import Creatable from 'react-select/creatable'

import {twMerge} from 'tailwind-merge'

import {Theme} from './Select.theme'
import {Components} from './components'
import {HelperText, Label} from '../../Display'
import {TagsProps} from './Tags.types'
import {hasArray} from 'src/app/utils/array-utils'
import {InputActionMeta, MultiValue} from 'react-select'

function getValue(values?: string[]) {
  if (hasArray(values)) {
    return values.map((_) => ({label: _, value: _}))
  }
  return null
}

export function Tags({
  onChangeValue,
  helperText,
  className,
  disabled,
  tagRef,
  error,
  label,
  value = [],
  ...props
}: TagsProps) {
  const handleChangeTabs = (value: MultiValue<{label: string; value: string}>) => {
    onChangeValue?.(value.map((_) => _.label))
  }

  const handleOnInputChange = (inputValue: string, {action}: InputActionMeta) => {
    if (action !== 'input-change') return
    const lastChar = inputValue[inputValue.length - 1]
    if (lastChar !== ',') return
    const valueToAdd = inputValue.slice(0, -1)
    if (value.includes(valueToAdd)) return ''
    onChangeValue?.([...value, valueToAdd])
    return ''
  }

  return (
    <div className={twMerge('relative', className)}>
      {!!label && (
        <Label required={props.required} {...{error}}>
          {label}
        </Label>
      )}
      <Creatable
        menuPosition='fixed'
        placeholder='Type to add'
        components={{...Components, DropdownIndicator: () => null}}
        isDisabled={disabled}
        onChange={handleChangeTabs}
        onInputChange={handleOnInputChange}
        isMulti={true}
        value={getValue(value)}
        ref={tagRef}
        classNames={{
          valueContainer: (_) => twMerge(Theme.valueContainer, 'py-0'),
          placeholder: () => twMerge(Theme.placeholder),
          singleValue: () => twMerge(Theme.singleValue),
          multiValue: (_) => twMerge(Theme.multiValue),
          control: (_) =>
            twMerge(
              Theme.control,
              error && Theme.controlError,
              error && _.isFocused && Theme.controlErrorFocus
            ),
          multiValueLabel: (_) => twMerge(Theme.multiValueLabel),
          indicatorsContainer: () => 'pr-3.5',
          input: () => twMerge(Theme.input),
          menuList: () => twMerge(Theme.menuList),
          option: (_) => twMerge(Theme.option, _.isFocused && Theme.optionFocus),
          menuPortal: () => 'hidden',
          menu: () => twMerge(Theme.menu),
        }}
        styles={{
          control: ({
            backgroundColor,
            borderRadius,
            borderColor,
            borderStyle,
            boxShadow,
            outline,
            ...base
          }) => ({
            ...base,
            borderWidth: 1,
            minHeight: 40,
          }),
        }}
      />
      {!!helperText && <HelperText {...{error}}>{helperText}</HelperText>}
    </div>
  )
}
