import { toAbsoluteUrl } from 'src/app/utils/asset-helpers-utils'

function Spinner() {
  return (
    <div className='fixed inset-0 flex items-center justify-center bg-white bg-opacity-40 backdrop-blur-sm z-[9999]'>
      <img src={toAbsoluteUrl('/logo_loading.png')} alt='loading logo' className='animate-spin' style={{height: '2em'}} />
    </div>
  )
}

export {Spinner}
