
export function hasText(str?: any): str is string {
  return !!str?.toString();
}

export function toCapitalize(str?: string | null) {
  if (!!str) {
    const strings = str.split(' ');
    const mapStrings = strings.map((item) => {
      const value = item.toLocaleLowerCase();
      const firstLetter = value.charAt(0).toUpperCase();
      return firstLetter + value.substring(1);
    });
    return mapStrings.join(' ');
  }
  return '';
}

export function reverseCoordinate(coordinate: string) {
  if (!coordinate || typeof coordinate !== 'string') {
    return null; // Mengembalikan null jika koordinat tidak valid
  }

  const parts = coordinate.split(',').map((part) => part.trim());
  
  if (parts.length !== 2 || isNaN(parseFloat(parts[0])) || isNaN(parseFloat(parts[1]))) {
    return null; // Mengembalikan null jika format tidak sesuai
  }

  return `${parts[1]} ${parts[0]}`; // Membalik menjadi long, lat
}