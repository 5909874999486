import {twMerge} from 'tailwind-merge'
import {Stack} from '../../Layouts'
import {PagingProps} from './Paging.types'
import {Theme} from './Paging.theme'
import {ChevronLeftIcon, ChevronRightIcon} from '../../Icons'
import {useUrlSearchParams} from 'src/app/hooks'

type _PagingProps<T> = PagingProps<T> & {
  searchParams?: {
    [x: string]: string | undefined
  }
  setSearchParams?: (props: {[x: string]: string | undefined}) => void
  limitKey?: string
  pageKey?: string
}

export function Paging<T>({
  className,
  query,
  searchParams,
  setSearchParams,
  limitKey = 'limit',
  pageKey = 'page',
  ...props
}: _PagingProps<T>) {
  const [defaultSearchParams, defaultSetSearchParams] = useUrlSearchParams<'page' | 'limit'>()
  setSearchParams = setSearchParams || defaultSetSearchParams
  const limit = searchParams?.[limitKey] || defaultSearchParams.limit || '0'
  const page = Number(searchParams?.[pageKey] || defaultSearchParams.page || '1')

  const total = query?.data?.pagination?.total || 0
  const totalPage = Math.ceil(+total / +limit)
  const loading = query?.isFetching || query?.isLoading

  const renderPages = () => {
    const pages = []

    // Always show the first two pages
    pages.push(<Button key={1} active={page === 1} page='1' {...{loading}} />)
    if (totalPage > 1) {
      pages.push(<Button key={2} active={page === 2} page='2' {...{loading}} />)
    }

    // Ellipsis before middle pages
    if (page > 4) {
      pages.push(<Etc key='ellipsis-start' visible />)
    }

    // Middle pages
    const startPage = Math.max(3, page - 1)
    const endPage = Math.min(totalPage - 2, page + 1)

    for (let i = startPage; i <= endPage; i++) {
      pages.push(<Button key={i} active={page === i} page={i.toString()} {...{loading}} />)
    }

    // Ellipsis after middle pages
    if (page < totalPage - 3) {
      pages.push(<Etc key='ellipsis-end' visible />)
    }

    // Always show the last two pages
    if (totalPage > 3) {
      pages.push(
        <Button
          key={totalPage - 1}
          active={page === totalPage - 1}
          page={(totalPage - 1).toString()}
          {...{loading}}
        />
      )
      pages.push(
        <Button
          key={totalPage}
          active={page === totalPage}
          page={totalPage.toString()}
          {...{loading}}
        />
      )
    }

    return pages
  }

  return (
    <Stack
      direction='row'
      justify='between'
      items='center'
      className={twMerge('ws:p-1 p-6 ws:!justify-start', className)}
      {...props}
    >
      <div>
        <select
          onChange={(_) => setSearchParams?.({[limitKey]: _.target.value, [pageKey]: '1'})}
          className={twMerge(Theme.select)}
          value={limit}
        >
          <option>10</option>
          <option>20</option>
          <option>50</option>
          <option>100</option>
        </select>
        <span className='text-neutral-700 font-medium text-xs ml-2'>Rows per page</span>
      </div>

      <Stack direction='row' gap={2} className='w-auto ws:!gap-1'>
        <button
          className={twMerge(Theme.button, loading && Theme.buttonLoading)}
          disabled={loading || page === 1}
          onClick={() => setSearchParams?.({page: (page - 1).toString()})}
          key='prev'
        >
          <ChevronLeftIcon />
        </button>
        {renderPages()}
        <button
          className={twMerge(Theme.button, loading && Theme.buttonLoading)}
          disabled={loading || page === totalPage}
          onClick={() => setSearchParams?.({[pageKey]: (page + 1).toString()})}
          key='next'
        >
          <ChevronRightIcon />
        </button>
      </Stack>
    </Stack>
  )
}

interface ButtonProps {
  disabled?: boolean
  loading?: boolean
  active?: boolean
  page: string
}

function Button(props: ButtonProps) {
  const [_, setSearchParams] = useUrlSearchParams<'page'>()

  return (
    <button
      disabled={props.disabled || props.loading || props.active}
      onClick={() => setSearchParams({page: props.page})}
      className={twMerge(
        Theme.button,
        props.loading && Theme.buttonLoading,
        props.active && Theme.buttonActive
      )}
    >
      {props.page}
    </button>
  )
}

function Etc({visible}: {visible: boolean}) {
  return visible ? <span className={twMerge(Theme.separator)}>...</span> : null
}
