import {SVGProps} from 'react'

export function DeleteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M16.6433 7.48826C16.6433 7.55626 16.1104 14.297 15.806 17.1339C15.6154 18.8749 14.493 19.9308 12.8095 19.9608C11.516 19.9898 10.2498 19.9998 9.00394 19.9998C7.68127 19.9998 6.38778 19.9898 5.13221 19.9608C3.50513 19.9218 2.38183 18.8449 2.20094 17.1339C1.88778 14.287 1.36454 7.55626 1.35482 7.48826C1.34509 7.28327 1.41123 7.08828 1.54544 6.93028C1.67771 6.78429 1.86833 6.69629 2.06867 6.69629H15.9392C16.1386 6.69629 16.3195 6.78429 16.4624 6.93028C16.5957 7.08828 16.6628 7.28327 16.6433 7.48826Z'
        fill='#A01713'
      />
      <path
        d='M18 3.97686C18 3.56588 17.6761 3.24389 17.2871 3.24389H14.3714C13.7781 3.24389 13.2627 2.8219 13.1304 2.22692L12.967 1.49795C12.7385 0.616978 11.9498 0 11.0647 0H6.93624C6.0415 0 5.26054 0.616978 5.02323 1.54595L4.87054 2.22792C4.7373 2.8219 4.22185 3.24389 3.62957 3.24389H0.713853C0.32386 3.24389 0 3.56588 0 3.97686V4.35685C0 4.75783 0.32386 5.08982 0.713853 5.08982H17.2871C17.6761 5.08982 18 4.75783 18 4.35685V3.97686Z'
        fill='#A01713'
      />
    </svg>
  )
}
