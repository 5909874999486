const CustomerManagementScreens = {
  CUSTOMER_MANAGEMENT: {
    PATH: '/customer-management',
    TITLE: 'Customer Management',
    TITLE_ALT: 'Customer Management',
    PERMISSION: 'customer.read'
  },
  CUSTOMER_MANAGEMENT_LIST: {
    PATH: '/customer-management/list',
    HEADER_NAME: 'Customer',
    TITLE: 'Customer',
    TITLE_ALT: 'List Customer',
    PERMISSION: 'customer.read'
  },
  CUSTOMER_MANAGEMENT_ADD: {
    PATH: '/customer-management/add',
    TITLE: 'Create Customer',
    TITLE_ALT: 'Create Customer',
    PERMISSION: 'customer.create'
  },
  CUSTOMER_MANAGEMENT_DETAIL: {
    PATH: '/customer-management/detail/:id',
    TITLE: 'Customer Detail',
    TITLE_ALT: 'Customer Detail',
    PERMISSION: 'customer.read'
  },
  CUSTOMER_MANAGEMENT_POINT: {
    PATH: '/customer-management/points/:id',
    TITLE: 'Customer Points',
    TITLE_ALT: 'Points Transaction History',
    PERMISSION: 'customer.read'
  },
  CUSTOMER_MANAGEMENT_REWARD: {
    PATH: '/customer-management/rewards/:id',
    TITLE: 'Rewards',
    TITLE_ALT: 'Customer Rewards',
    PERMISSION: 'customer.read'
  },
  CUSTOMER_MANAGEMENT_COMMISSION: {
    PATH: '/customer-management/commission/:id',
    TITLE: 'Commission',
    TITLE_ALT: 'Customer Commission',
    PERMISSION: 'customer.read'
  },
  CUSTOMER_MANAGEMENT_EDIT: {
    PATH: '/customer-management/edit/:id',
    TITLE: 'Edit Customer',
    TITLE_ALT: 'Edit Customer',
    PERMISSION: 'customer.update'
  },
  INSURANCE_POLICY_LIST: {
    PATH: '/customer-management/insurance-policy/:id',
    TITLE: 'List Policy',
    TITLE_ALT: 'Insurance Policy',
    PERMISSION: 'customer.read'
  },
  CLAIM_HISTORY_LIST: {
    PATH: '/customer-management/claim-history/:id',
    TITLE: 'List Claim History',
    TITLE_ALT: 'List Claim',
    PERMISSION: 'customer.read'
  }
}

export default CustomerManagementScreens
