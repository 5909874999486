const CustomerManagementScreens = {
  CUSTOMER_MANAGEMENT: {
    PATH: '/customer-management',
    TITLE: 'Customer Management',
    TITLE_ALT: 'Customer Management',
  },
  CUSTOMER_MANAGEMENT_LIST: {
    PATH: '/customer-management/list',
    HEADER_NAME: 'Customer',
    TITLE: 'Customer',
    TITLE_ALT: 'List Customer',
  },
  CUSTOMER_MANAGEMENT_ADD: {
    PATH: '/customer-management/add',
    TITLE: 'Create Customer',
    TITLE_ALT: 'Create Customer',
  },
  CUSTOMER_MANAGEMENT_DETAIL: {
    PATH: '/customer-management/detail/:id',
    TITLE: 'Customer Detail',
    TITLE_ALT: 'Customer Detail',
  },
  CUSTOMER_MANAGEMENT_EDIT: {
    PATH: '/customer-management/edit/:id',
    TITLE: 'Edit Customer',
    TITLE_ALT: 'Edit Customer',
  },
  INSURANCE_POLICY_LIST: {
    PATH: '/customer-management/insurance-policy/:id',
    TITLE: 'List Policy',
    TITLE_ALT: 'Insurance Policy',
  },
  CLAIM_HISTORY_LIST: {
    PATH: '/customer-management/claim-history/:id',
    TITLE: 'List Claim History',
    TITLE_ALT: 'List Claim',
  }
}

export default CustomerManagementScreens
