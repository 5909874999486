import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {twMerge} from 'tailwind-merge'

import {useUrlSearchParams} from 'src/app/hooks'
import {SearchIcon} from '../../Icons'
import {CloseIcon} from '../../Icons/InputIcons/CloseIcon'
import {Select, TextField} from '../../Inputs'
import {InputSearchProps} from './InputSearch.types'

export function InputSearch({
  className,
  selectable = false,
  options = [],
  clearTrigger, // Tambahkan prop ini
  ...props
}: InputSearchProps & { clearTrigger?: number }) { // clearTrigger opsional
  const [searchParams, setSearchParams] = useUrlSearchParams<'search' | 'page' | 'search_by'>()
  const timeoutRef = useRef<NodeJS.Timeout>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [selected, setSelected] = useState<(typeof options)[0]>()

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    clearTimeout(timeoutRef.current)
    if (inputValue.length >= 3) {
      timeoutRef.current = setTimeout(() => {
        setSearchParams({
          search: inputValue,
          page: '1',
          search_by: selected?.value,
        })
      }, 400)
    } else {
      setSearchParams({ search: undefined, page: '1', search_by: '' })
    }
  }

  const handleClear = () => {
    clearTimeout(timeoutRef.current)
    setSearchParams({ search: undefined, page: '1', search_by: '' })
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const handleSelectChange = (selected: any) => {
    setSelected(selected)
    if (searchParams.search) {
      setSearchParams({
        search: searchParams.search,
        page: '1',
        search_by: selected.value,
      })
    }
  }

  useEffect(() => {
    if (!searchParams.search_by) {
      setSelected(options[0])
    } else {
      setSelected(options.find((option) => option.value === searchParams.search_by))
    }
  }, [searchParams.search_by, options])

  // Perhatikan perubahan pada clearTrigger, jika berubah, panggil handleClear
  useEffect(() => {
    if (clearTrigger) {
      handleClear()
    }
  }, [clearTrigger])

  return (
    <div className={twMerge('max-w-[500px] w-full', className)}>
      {selectable && (
        <Select
          className='w-[200px]'
          onChangeValue={handleSelectChange}
          options={options as any}
          value={selected}
          {...props}
        />
      )}
      <TextField
        ref={inputRef}
        defaultValue={searchParams.search}
        placeholder='Search'
        className={twMerge('w-full')}
        startIcon={SearchIcon}
        endIcon={searchParams.search ? CloseIcon : undefined}
        onChange={handleSearch}
        onClickEndIcon={handleClear}
        classes={{
          endIconWrapper: searchParams.search && 'cursor-pointer !pointer-events-auto z-10',
        }}
        {...props}
      />
    </div>
  )
}
