import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../components/Layout/FallbackView'
import BroadcastScreens from '../modules/broadcast/Screen'
import CampaignScreens from '../modules/campaign/Screen'
import DashboardScreens from '../modules/dashboard/Screens'
import GeneralConfigScreens from '../modules/general-config/Screen'
import ProductManagementScreens from '../modules/product-management/Screens'
import UserManagementScreens from '../modules/user-management/Screen'
import { ModulesComponentScreens } from '../modules_component/Screens'
import CustomerManagementScreens from '../modules/customer/Screen'
import LocationScreens from '../modules/location/Screens'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))

  const ManageUserPage = lazy(() => import('../modules/user-management/ManageUserRoutes'))
  const ManageRolePage = lazy(() => import('../modules/user-management/ManageRoleRoutes'))
  const GeneralConfigPage = lazy(() => import('../modules/general-config/GeneralConfigRoutes'))

  const CampaignPage = lazy(() => import('../modules/campaign/CampaignRoutes'))
  const BroadcastPage = lazy(() => import('../modules/broadcast/BroadcastRoutes'))
  const LocationPage = lazy(() => import('../modules/location/LocationRoutes'))

  const ProductManagementPage = lazy(() => import('../modules/product-management/ProductManagementRoutes'))
  const CustomerManagementPage = lazy(() => import('../modules/customer/CustomerManagementRoutes'))

  //modules_components
  const ModulesComponentsPage = lazy(() => import('../modules_component/ModulesComponentRoutes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={ProductManagementScreens.PRODUCT_MANAGEMENT.PATH} component={ProductManagementPage} />
          <Route path={CustomerManagementScreens.CUSTOMER_MANAGEMENT.PATH} component={CustomerManagementPage} />
          <Route path={UserManagementScreens.MANAGE_USER.PATH} component={ManageUserPage} />
          <Route path={UserManagementScreens.MANAGE_ROLE.PATH} component={ManageRolePage} />
          <Route path={GeneralConfigScreens.GENERAL_CONFIG.PATH} component={GeneralConfigPage} />
          <Route path={CampaignScreens.CAMPAIGN.PATH} component={CampaignPage} />
          <Route path={BroadcastScreens.BROADCAST.PATH} component={BroadcastPage} />
          <Route path={LocationScreens.LOCATION.PATH} component={LocationPage} />
          <Route
            path={ModulesComponentScreens.MODULES_COMPONENT.PATH}
            component={ModulesComponentsPage}
          />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
