const DashboardScreens = {
  DASHBOARD: {
    PATH: '/dashboard',
    TITLE: 'Dashboard',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'dashboard.read',
  },
  DASHBOARD_USER_DATA_MOBILE: {
    PATH: '/dashboard/user-data-mobile',
    TITLE: 'User Data Mobile',
    TITLE_ALT: 'User Data Mobile',
    PERMISSION: 'dashboard.read',
  },
  DASHBOARD_POLICY_DATA: {
    PATH: '/dashboard/policy-data',
    TITLE: 'Policy Data',
    TITLE_ALT: 'Policy Data',
    PERMISSION: 'dashboard.read',
  },
  DASHBOARD_PREMIUM_DATA: {
    PATH: '/dashboard/premium-data',
    TITLE: 'Premium Data',
    TITLE_ALT: 'Premium Data',
    PERMISSION: 'dashboard.read',
  },
  DASHBOARD_CLAIM_DATA: {
    PATH: '/dashboard/claim-data',
    TITLE: 'Claim Data',
    TITLE_ALT: 'Claim Data',
    PERMISSION: 'dashboard.read',
  },
}

export default DashboardScreens
