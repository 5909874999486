import {SVGProps} from 'react'

export function FilterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.5715 13.5941L20.4266 7.72014C20.7929 7.35183 21 6.84877 21 6.32376V4.60099C21 3.52002 20.1423 3 19.0844 3H4.91556C3.85765 3 3 3.52002 3 4.60099V6.3547C3 6.85177 3.18462 7.33087 3.51772 7.69419L8.89711 13.5632C8.9987 13.674 9.14034 13.7368 9.28979 13.7378L14.1915 13.7518C14.3332 13.7528 14.4699 13.6969 14.5715 13.5941Z'
        fill='currentColor'
      />
      <path
        opacity='0.4'
        d='M9.05634 13.6855V20.2901C9.05634 20.5307 9.17746 20.7573 9.37576 20.887C9.48907 20.9619 9.61996 20.9998 9.75086 20.9998C9.84952 20.9998 9.94818 20.9789 10.04 20.9369L14.0059 19.0884C14.254 18.9736 14.4133 18.7211 14.4133 18.4426V13.6855H9.05634Z'
        fill='currentColor'
      />
    </svg>
  )
}
