import axios from 'axios'
import {BaseResponse, Pagination, QueryModel, Response} from '../../../models/api.types'
import {UserModel} from '../models/ManageUser.model'

export const URL_GET_PROFILE = 'api/v1/cms/users/profile'
export const URL_USER = 'api/v1/cms/users'

export const getManageUser = async (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<UserModel[]>>>(URL_USER, {
    params,
  })
}

export const getManageUserDetail = (id: string) => {
  return axios.get<BaseResponse<Response<UserModel>>>(`${URL_USER}/${id}/detail`)
}

export const saveManageUser = (params: UserModel) => {
  const role_id = params.role?.id
  const username = params?.username
  const name = params?.name
  const email = params?.email
  const password = params?.password
  const password_expiry_date = params?.password_expiry_date
  const position = params?.position
  const is_ldap_user = params?.is_ldap_user
  const status = params?.status === 'ACTIVE' ? true : false
  const nip = params?.nip

  const addPayload = {
    name,
    username,
    email,
    role_id,
    password,
    password_expiry_date,
    position,
    is_ldap_user,
    status,
    nip,
  }

  const editPayload = {
    name,
    username,
    email,
    role_id,
    password,
    password_expiry_date,
    position,
    is_ldap_user,
    status,
    nip,
  }

  if (!params.id) {
    return axios.post<BaseResponse<Response<UserModel>>>(URL_USER, addPayload)
  }

  return axios.put<BaseResponse<Response<UserModel>>>(`${URL_USER}/${params.id}`, editPayload)
}

export const getSearchLDAPUser = async (params?: any) => {
  return axios.post<BaseResponse<Response<UserModel>>>(`${URL_USER}/find-ldap`, params)
}

export const getCheckPasswordSearchLDAPUser = async (params?: any) => {
  return axios.post<BaseResponse<Response<UserModel>>>(`${URL_USER}/login-ldap`, params)
}

export function getProfile() {
  return axios.get<BaseResponse<Response<UserModel>>>(URL_GET_PROFILE)
}

export const deleteUser = (id: string) => {
  return axios.delete(`${URL_USER}/${id}`)
}
