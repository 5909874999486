import {SVGProps} from 'react'

export function DangerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M4.723 21.1672C4.71 21.1672 4.698 21.1672 4.684 21.1662C4.369 21.1502 4.06 21.0822 3.766 20.9632C2.319 20.3752 1.621 18.7222 2.208 17.2762L9.529 4.45025C9.781 3.99425 10.163 3.61225 10.629 3.35425C11.994 2.59825 13.72 3.09525 14.475 4.45925L21.748 17.1872C21.91 17.5682 21.979 17.8782 21.996 18.1942C22.035 18.9502 21.777 19.6752 21.271 20.2362C20.765 20.7972 20.07 21.1282 19.315 21.1662L4.795 21.1672H4.723Z'
        fill='#A01713'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.125 10.0208C11.125 9.53875 11.518 9.14575 12 9.14575C12.482 9.14575 12.875 9.53875 12.875 10.0208V12.8488C12.875 13.3318 12.482 13.7238 12 13.7238C11.518 13.7238 11.125 13.3318 11.125 12.8488V10.0208ZM11.125 16.2699C11.125 15.7849 11.518 15.3899 12 15.3899C12.482 15.3899 12.875 15.7799 12.875 16.2589C12.875 16.7519 12.482 17.1449 12 17.1449C11.518 17.1449 11.125 16.7519 11.125 16.2699Z'
        fill='#A01713'
      />
    </svg>
  )
}
