import {SVGProps} from 'react'

export function DeleteIconBB(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M11.0955 5.32559C11.0955 5.37092 10.7402 9.86476 10.5373 11.756C10.4102 12.9167 9.66196 13.6206 8.53963 13.6406C7.6773 13.66 6.83313 13.6666 6.00257 13.6666C5.12079 13.6666 4.25846 13.66 3.42141 13.6406C2.33669 13.6146 1.58783 12.8967 1.46723 11.756C1.25846 9.8581 0.909635 5.37092 0.903152 5.32559C0.896668 5.18893 0.940757 5.05893 1.03023 4.9536C1.11841 4.85627 1.24549 4.79761 1.37905 4.79761H10.6261C10.759 4.79761 10.8796 4.85627 10.9749 4.9536C11.0637 5.05893 11.1085 5.18893 11.0955 5.32559Z'
        fill='#A01713'
      />
      <path
        d='M12 2.98449C12 2.7105 11.7841 2.49584 11.5247 2.49584H9.58094C9.18543 2.49584 8.8418 2.21452 8.75362 1.81787L8.64469 1.33188C8.49233 0.744571 7.9665 0.333252 7.37649 0.333252H4.62416C4.02766 0.333252 3.50702 0.744571 3.34882 1.36388L3.24703 1.81853C3.1582 2.21452 2.81457 2.49584 2.41971 2.49584H0.475902C0.215907 2.49584 0 2.7105 0 2.98449V3.23782C0 3.50514 0.215907 3.72647 0.475902 3.72647H11.5247C11.7841 3.72647 12 3.50514 12 3.23782V2.98449Z'
        fill='#A01713'
      />
    </svg>
  )
}
