
export const Theme = {
  chip: [
    'text-xs inline-block font-medium me-2 px-2.5 py-0.5 rounded-xl',
    'bg-primary-100 text-primary-800',
    'dark:bg-primary-900 dark:text-primary-300'
  ]
}

export const ChipVariants = {
  success: [
    'bg-success-100 text-success-400',
    'dark:bg-success-900 dark:text-success-300'
  ],
  warning: [
    'bg-warning-50 text-warning-600',
    'dark:bg-warning-900 dark:text-warning-300'
  ],
  info: [
    'bg-info-100 text-info-400',
    'dark:bg-info-900 dark:text-info-300'
  ],
  danger: [
    'bg-danger-100 text-danger-400',
    'dark:bg-danger-900 dark:text-danger-300'
  ],
  gray: [
    'bg-gray-100 text-gray-400',
    'dark:bg-gray-700 dark:text-gray-300'
  ],
  "dark-gray": [
    'bg-neutral-400 text-white',
    'dark:bg-white dark:text-neutral-400'
  ],
};
