import {SVGProps} from 'react'

export function EditIconBB(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M13.3284 12.6355H9.53221C9.16184 12.6355 8.8606 12.9415 8.8606 13.3177C8.8606 13.6947 9.16184 14 9.53221 14H13.3284C13.6988 14 14 13.6947 14 13.3177C14 12.9415 13.6988 12.6355 13.3284 12.6355Z'
        fill='#DBB200'
      />
      <path
        d='M6.87268 4.60257L10.4699 7.50929C10.5567 7.57881 10.5715 7.70637 10.5038 7.79524L6.23916 13.3521C5.97108 13.6954 5.57601 13.8896 5.15272 13.8968L2.82464 13.9254C2.70048 13.9269 2.59183 13.8409 2.56361 13.7176L2.0345 11.4172C1.94279 10.9944 2.0345 10.5572 2.30259 10.2204L6.58837 4.63697C6.65751 4.54739 6.7852 4.53162 6.87268 4.60257Z'
        fill='#DBB200'
      />
      <path
        opacity='0.4'
        d='M12.0804 5.77696L11.3869 6.64267C11.3171 6.73082 11.1915 6.74515 11.1047 6.67492C10.2617 5.99267 8.10293 4.2419 7.50397 3.75673C7.41649 3.68506 7.4045 3.5575 7.47505 3.46863L8.14384 2.63804C8.75056 1.85689 9.80878 1.78523 10.6624 2.46604L11.643 3.24719C12.0451 3.56251 12.3132 3.97817 12.4049 4.41533C12.5108 4.8962 12.3979 5.36847 12.0804 5.77696Z'
        fill='#DBB200'
      />
    </svg>
  )
}
