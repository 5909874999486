const UserManagementScreens = {
  MANAGE_USER: {
    PATH: '/user-manage',
    TITLE: 'User Management',
    TITLE_ALT: 'Manage User',
  },
  LIST_MANAGE_USER: {
    PATH: '/user-manage/list',
    HEADER_NAME: 'Manage User List',
    TITLE: 'Manage User',
    TITLE_ALT: 'List User',
  },
  ADD_MANAGE_USER: {
    PATH: '/user-manage/add',
    HEADER_NAME: 'Create User',
    TITLE: 'Create User',
    TITLE_ALT: 'Create User',
  },
  DETAIL_MANAGE_USER: {
    PATH: '/user-manage/list/detail/:id',
    TITLE: 'Manage Detail User',
    TITLE_ALT: 'Manage Detail User',
  },
  EDIT_MANAGE_USER: {
    PATH: '/user-manage/list/edit/:id',
    TITLE: 'Manage Edit User',
    TITLE_ALT: 'Edit User',
  },
  MANAGE_ROLE: {
    PATH: '/role-manage',
    TITLE: 'User Management',
    TITLE_ALT: 'Manage Role',
  },
  LIST_MANAGE_ROLE: {
    PATH: '/role-manage/list',
    HEADER_NAME: 'Manage Role',
    TITLE: 'Manage Role',
    TITLE_ALT: 'List Role',
  },
  ADD_MANAGE_ROLE: {
    PATH: '/role-manage/add',
    HEADER_NAME: 'Manage Add Role',
    TITLE: 'Manage Add Role',
    TITLE_ALT: 'Create Role',
  },
  EDIT_MANAGE_ROLE: {
    PATH: '/role-manage/edit/:id',
    HEADER_NAME: 'Manage Edit Role',
    TITLE: 'Manage Edit Role',
    TITLE_ALT: 'Edit Role',
  },
  DETAIL_MANAGE_ROLE: {
    PATH: '/role-manage/detail/:id',
    HEADER_NAME: 'Detail Role',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
  },
}

export default UserManagementScreens
