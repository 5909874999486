import { Controller } from "react-hook-form";

import Labels from "src/app/constants/labels";
import { TextEditor } from "../../Inputs";
import { useDisabledField } from "../HookForm/HookForm.context";
import { InputTextEditorProps } from "./InputTextEditor.types";

export function InputTextEditor({
  disabled,
  form,
  name,
  rule,
  ...props
}: InputTextEditorProps) {

  const isDisabled = useDisabledField(name);

  return (
    <Controller
      disabled={isDisabled || disabled}
      control={form.control}
      rules={rule}
      name={name}
      render={({ field, fieldState }) => {

        const error = fieldState.error;
        const errorMessage = rule?.required === true
          ? Labels.FIELD_REQUIRED
          : error?.message?.toString();

        return (
          <TextEditor
            helperText={!error ? props.helperText : errorMessage}
            onChange={(_) => field.onChange(_)}
            required={!!rule?.required}
            disabled={field.disabled}
            value={field.value}
            error={!!error}
            onInit={(_, ref) => {
              field.ref(ref);
            }}
          />
        );
      }}
    />
  );
}
