import {Mutex} from 'async-mutex'
import axios, {AxiosError, AxiosResponse, AxiosStatic} from 'axios'
import {BaseResponse} from 'src/app/models/api.types'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import {Refs} from 'src/app/utils/refs-utils'

const mutex = new Mutex()
const plainAxios = axios.create()
const methodsWithToast = ['post', 'put', 'delete', 'patch']
const responseCodeWithNoToast: string[] = [
  'BCAI-400',
  'CMS-USERS-LDAP-404',
  'CMS-USERS-LDAP-SUBMIT-400',
  'CMS-ROLE-DELETE-CONFIRMATION-400',
  'CMS-ROLE-VALIDATION-ROLENAME-403',
  'BCAI-PRODUCT-EXIST-400',
  'BCAI-SURVEY-CREATION-400'
]

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL

  localAxios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessToken}`
      } else if (!config.headers['app-token']) {
        config.headers['app-token'] = process.env.REACT_APP_API_TOKEN
      }

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )
  localAxios.interceptors.response.use(
    (response: AxiosResponse<BaseResponse<any>, any>) => {
      const {data} = response ?? {}
      if (data?.response_schema?.response_code === 'CMS-USERS-PROFILE-200') {
        if (data.response_output?.detail?.status === 'INACTIVE') {
          Refs.pageToast.show({
            elementId: 'dialog-wrapper-content',
            message: 'Your account is inactive, please contact your administrator',
            severity: 'danger',
          })
          setTimeout(() => {
            store.dispatch(AuthRedux.actions.logout())
          }, 3000)
        }
      }

      if (
        !response.config.headers['With-No-PageToast'] &&
        !response.config.headers['With-No-PageToast-On-Success'] &&
        !!response.config.headers['Authorization'] &&
        methodsWithToast.includes(response.config.method!) &&
        !responseCodeWithNoToast.includes(data.response_schema?.response_code ?? '')
      ) {
        Refs.pageToast.show({
          message: response.data.response_schema?.response_message?.en!,
          severity: 'success',
        })
      }
      return response
    },
    async (error: AxiosError<BaseResponse<any>>) => {
      const {
        auth: {refreshToken},
      } = store.getState()
      const {dispatch} = store
      const {data} = error.response ?? {}

      if (data?.response_schema?.response_code === 'BCAI-AUTH-GUARD-401') {
        return mutex.runExclusive(async () => {
          const refresh = await _refreshToken(refreshToken)

          if (refresh && !!error.config) {
            error.config.headers.Authorization = `Bearer ${store.getState().auth.accessToken}`
            try {
              return await plainAxios.request(error.config)
            } catch (err: any) {
              if (err.response?.status === 401) dispatch(AuthRedux.actions.logout())
              throw err
            }
          }
          dispatch(AuthRedux.actions.logout())
          throw error
        })
      } else if (data?.response_schema?.response_code === 'BCAI-AUTH-GUARD-409') {
        window.location.href = '/logout'
      } else if (data?.response_schema?.response_code === 'BCAI-ACCESS-VALIDATION-403') {
        window.location.href = '/logout'
      }
      // Handle DTO error from backend
      else if (responseCodeWithNoToast.includes(data?.response_schema?.response_code ?? '')) {
        // don't show toast
      } else if (
        !!error.response &&
        !error.response.config.headers['With-No-PageToast'] &&
        !error.response.config.headers['With-No-PageToast-On-Error'] &&
        !!error.response.config.headers['Authorization'] &&
        methodsWithToast.includes(error.response.config.method!)
      ) {
        Refs.pageToast.show({
          elementId: 'dialog-wrapper-content',
          message: error.response.data.response_schema?.response_message?.en!,
          severity: 'danger',
        })
      }
      throw error
    }
  )
  const _refreshToken = async (refreshToken: any) => {
    const {dispatch} = store
    try {
      const result = await plainAxios.get(`/v1/cms/refresh`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      if (result.data)
        dispatch(
          AuthRedux.actions.fulfillToken({
            token: result.data.response_output?.detail.token,
            refreshToken: refreshToken,
          })
        )
      return result.data
    } catch (error) {
      dispatch(AuthRedux.actions.logout())
      throw error
    }
  }
}
