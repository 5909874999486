import {Controller, useFormContext} from 'react-hook-form'
import {GroupBase, Props} from 'react-select'

import {OmitSelectAsyncProps, SelectAsync} from '../../Inputs'
import {InputSelectAsyncProps} from './InputSelectAsync.types'
import {useDisabledField} from '../HookForm/HookForm.context'

export function InputSelectAsync<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  onChangeValue,
  disabled,
  rule,
  name,
  ...props
}: InputSelectAsyncProps<Option> & Omit<Props<Option, IsMulti, Group>, OmitSelectAsyncProps>) {
  const isDisabled = useDisabledField(name)
  const form = useFormContext()

  return (
    <Controller
      control={form.control}
      rules={rule}
      name={name}
      render={({field, fieldState}) => {
        const isError = !!fieldState.error
        return (
          <SelectAsync
            {...props}
            helperText={isError ? fieldState.error?.message?.toString() : props.helperText}
            required={!!rule?.required}
            disabled={isDisabled || disabled}
            error={isError}
            value={field.value}
            selectRef={field.ref}
            onChangeValue={(_: any) => {
              field.onChange(_)
              field.onBlur()
              onChangeValue?.(_)
            }}
            menuPlacement='auto'
          />
        )
      }}
    />
  )
}
