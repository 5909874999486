import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {HeaderToast} from 'src/app/components/Libs'
import {HeaderToastProvider} from 'src/app/context/ToastContext'
import AuthScreens from './Screens'
import GuestWrapper from './components/GuestWrapper'

const AuthRoutes: FC = () => {
  return (
    <HeaderToastProvider>
      <HeaderToast variant='FULL' />

      <GuestWrapper>
        <Switch>
          <Route
            path={AuthScreens.LOGIN.PATH}
            component={AuthScreens.LOGIN.COMPONENT}
          />
          <Redirect from='/auth' exact={true} to='/auth/login' />
          <Redirect to='/auth/login' />
        </Switch>
      </GuestWrapper>
    </HeaderToastProvider>
  )
}

export default AuthRoutes
