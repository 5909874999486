import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import Select, {ClassNamesConfig, SelectComponentsConfig} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {CustomReactMultiSelect} from '../../Custom/CustomReactSelect'
import {FormError} from '../FormError'
import {FormMultiSelectProps, MultiSelectOption} from './Multiselect.types'

export const FormMultiSelect: FC<FormMultiSelectProps> = ({
  options,
  placeholder,
  error,
  touched,
  defaultValue,
  className,
  changedValue,
  disabled,
  selectedValue,
  asyncSelect,
  loadOptions,
  allowSelectAll = false,
  allowSelectAllPlaceholder = 'All Items',
  cacheUniqs,
}) => {
  const [val, setVal] = useState<MultiSelectOption[]>([])
  const [asyncVal, setAsyncVal] = useState<MultiSelectOption[]>([])

  const selectAllOption = useMemo(
    () => ({
      value: '<SELECT_ALL>',
      label: allowSelectAllPlaceholder,
    }),
    [allowSelectAllPlaceholder]
  )

  const isSelectAllSelected = useCallback(() => {
    if (!asyncSelect) return val?.length === options.length
    if (asyncSelect) return val[0]?.value === selectAllOption.value
  }, [asyncSelect, val, options, selectAllOption.value])

  const isOptionSelected = useCallback(
    (option: any) => val.some(({value}) => value === option.value) || isSelectAllSelected(),
    [isSelectAllSelected, val]
  )

  const getOptions = useCallback(() => {
    if (allowSelectAll) return [selectAllOption, ...options]
    else return options
  }, [allowSelectAll, selectAllOption, options])

  const getAsyncOptions = useCallback(
    async (search: string, loadedOptions: any) => {
      const previousOptions: Array<any> = allowSelectAll ? loadedOptions.slice(1) : loadedOptions
      const payload = await loadOptions(search, previousOptions)

      const newSelectAll = {
        options: allowSelectAll && loadedOptions.length === 0 ? [selectAllOption] : [],
        hasMore: payload.hasMore,
      }

      newSelectAll.options.push(...payload.options)

      if (!cacheUniqs) {
        setAsyncVal((prev) => [...prev, ...payload.options])
      }

      if (cacheUniqs) {
        setAsyncVal(() => [...previousOptions, ...payload.options])
      }

      return newSelectAll
    },
    [allowSelectAll, selectAllOption, loadOptions, cacheUniqs]
  )

  const getValue = useCallback(() => {
    if (allowSelectAll) {
      return isSelectAllSelected() ? [selectAllOption] : val
    } else {
      return val
    }
  }, [allowSelectAll, isSelectAllSelected, selectAllOption, val])

  const selectChanges = useCallback(
    (v: MultiSelectOption[], actionMeta: any) => {
      const {action, option, removedValue} = actionMeta

      if (changedValue) {
        if (!asyncSelect) {
          if (action === 'select-option' && option.value === selectAllOption.value) {
            changedValue(options, actionMeta)
            setVal(options)
          } else if (
            (action === 'deselect-option' && option.value === selectAllOption.value) ||
            (action === 'remove-value' && removedValue.value === selectAllOption.value)
          ) {
            changedValue([], actionMeta)
            setVal([])
          } else if (action === 'deselect-option' && isSelectAllSelected()) {
            changedValue(
              options.filter(({value}) => value !== option.value),
              actionMeta
            )
            setVal(options.filter(({value}) => value !== option.value))
          } else {
            changedValue(v || [], actionMeta)
            setVal(v)
          }
        }
      }
    },
    [changedValue, isSelectAllSelected, options, selectAllOption, asyncSelect]
  )

  const selectChangesAsync = useCallback(
    (v: MultiSelectOption[], actionMeta: any) => {
      const {action, option, removedValue} = actionMeta

      if (changedValue) {
        if (asyncSelect) {
          if (action === 'select-option' && option.value === selectAllOption.value) {
            changedValue([selectAllOption], actionMeta)
            setVal([selectAllOption])
          } else if (
            (action === 'deselect-option' && option.value === selectAllOption.value) ||
            (action === 'remove-value' && removedValue.value === selectAllOption.value)
          ) {
            changedValue([], actionMeta)
            setVal([])
          } else if (action === 'deselect-option' && isSelectAllSelected()) {
            changedValue(
              asyncVal.filter(({value}) => value !== option.value),
              actionMeta
            )
            setVal(asyncVal.filter(({value}) => value !== option.value))
          } else {
            changedValue(v || [], actionMeta)
            setVal(v)
          }
        }
      }
    },
    [changedValue, isSelectAllSelected, selectAllOption, asyncVal, asyncSelect]
  )

  const onChange = useCallback(
    (v: MultiSelectOption[], actionMeta: any) => {
      selectChanges(v, actionMeta)
      selectChangesAsync(v, actionMeta)
    },
    [selectChanges, selectChangesAsync]
  )

  useEffect(() => {
    if (!asyncSelect) {
      if (selectedValue?.length === 0 || selectedValue === undefined) {
        setVal([])
      } else {
        setVal(selectedValue)
      }
    }
  }, [selectedValue, options, asyncSelect])

  useEffect(() => {
    if (asyncSelect && defaultValue) {
      setVal(defaultValue)
    }
  }, [defaultValue, asyncSelect])

  const hardClassName: ClassNamesConfig<any, boolean, any> = {
    placeholder: (state) => 'text-neutral-60 font-medium',
    control: () =>
      `text-fs-9 rounded-lg font-medium text-neutral-100 ${
        touched && error ? 'border-danger' : 'border-none'
      } ${disabled && 'bg-neutral-40'}`,
    valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0' : 'py-3'}`,
    input: () => 'm-0 p-0',
    dropdownIndicator: (state) =>
      `transition-transform duration-150 ${
        state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
      }`,
    indicatorsContainer: () => 'min-h-[44px]',
    option: (state) =>
      `text-fs-9 rounded px-2 py-3 cursor-pointer ${
        state.isFocused ? 'text-primary' : 'text-neutral-80'
      }`,
    menuList: () => 'p-3 border-none max-h-[300px]',
    menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
  }

  return (
    <div data-testid='formselect-test' className={className}>
      {asyncSelect ? (
        <AsyncPaginate
          isOptionSelected={isOptionSelected as any}
          data-testid='formselect-test-value'
          defaultOptions
          cacheUniqs={cacheUniqs}
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={getAsyncOptions}
          debounceTimeout={500}
          components={CustomReactMultiSelect as SelectComponentsConfig<any, boolean, any>}
          value={getValue()}
          defaultValue={defaultValue}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti={true}
          classNames={hardClassName}
          isSearchable
        />
      ) : (
        <Select
          isOptionSelected={isOptionSelected as any}
          data-testid='formselect-test-value'
          components={CustomReactMultiSelect as SelectComponentsConfig<any, boolean, any>}
          options={getOptions()}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={getValue()}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti={true}
          classNames={hardClassName}
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
