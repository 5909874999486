/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { FC, useRef } from 'react'
import { useMasterLayout } from 'src/app/context/MasterContext'
import GIcon from '../../Libs/Icon/GIcon'
import { KTSVG } from '../../Libs/Icon/KTSVG'
import { DefaultTitle } from './DefaultTitle'
import { Alert, DialogRef, Menu, MenuItem } from '../../Feedback'
import { Typography } from '../../Display'
import {useGetProfile} from '../../../modules/user-management/providers/ManageUser.provider'
import {useLogout} from '../../../modules/auth/providers/AuthProviders'

const HeaderWrapper: FC = () => {
  const { minimize, setMinimize, currentTheme, changeCurrentTheme } = useMasterLayout()

  const alertLogoutRef = useRef<DialogRef>(null);
  const profile = useGetProfile();
  const logout = useLogout({ onSettled: () => alertLogoutRef.current?.hide() });

  return (
    <>
      <div
        className={clsx(
          'fixed top-0 right-0 z-[99] h-[55px] p-0 shadow-none bg-white flex items-stretch transition-all duration-300',
          'lg:h-[80px]',
          'dark:bg-inverse-primary-light-1 dark:text-white',
          { 'left-0 lg:left-[264px]': !minimize },
          { 'left-0 lg:left-[72px]': minimize }
        )}
      >
        <div className={clsx('px-8 flex items-stretch justify-between w-full mx-auto')}>
          <div className='flex items-center mr-1 lg:hidden'>
            <div
              onClick={() => setMinimize(!minimize)}
              className='inline-flex items-center justify-center w-10 h-10 px-4 border-0 shadow-none outline-none'
            >
              <KTSVG path='/media/icons/IconBurger.svg' className='text-neutral-100' />
            </div>
          </div>

          <div className={clsx('hidden items-stretch justify-between', 'lg:grow lg:flex')}>
            <div className='flex items-center'>
              <DefaultTitle />
            </div>
          </div>

          <div className='flex items-stretch'>
            <div className='flex items-center justify-center'>
              <div
                className='mr-4 cursor-pointer'
                onClick={() => changeCurrentTheme(currentTheme === 'light' ? 'dark' : 'light')}
              >
                {currentTheme === 'light' ? (
                  <GIcon icon='IconSun' className='w-5 h-5 text-primary' />
                ) : (
                  <GIcon icon='IconMoon' className='w-5 h-5' />
                )}
              </div>
              <Menu
                closeOnClick={true}
                className='min-w-[140px]'
                menuButton={
                  <div className='flex items-center cursor-pointer select-none'>
                    <div className='text-end'>
                      <Typography variant='body2'>Hi, {profile.data?.name}</Typography>
                      <Typography variant="caption">{profile.data?.role?.role_name}</Typography>
                    </div>
                    <div className='flex items-center justify-center ml-4'>
                      <div className='flex items-center justify-center w-10 h-10 font-bold rounded-full bg-neutral-20 text-primary'>
                        {profile.data?.name?.charAt(0).toUpperCase()}
                      </div>
                    </div>
                  </div>
                }
              >
                <MenuItem onClick={() => alertLogoutRef.current?.show()}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <Alert
        submitText='Logout'
        title='Logout'
        onSubmit={() => logout.mutate({})}
        loading={logout.isLoading}
        ref={alertLogoutRef}
      >
        Are you sure you want to logout from your account?
      </Alert>
    </>
  )
}

export default HeaderWrapper
