import {useForm} from 'react-hook-form'
import {useLoginByUsername} from '../../providers/AuthProviders'

const defaultForm = {
  username: '',
  password: '',
}

export function useLogin() {
  const loginUsername = useLoginByUsername<keyof typeof defaultForm>()
  const form = useForm({defaultValues: defaultForm})

  const handleSubmit = form.handleSubmit((formData) => {
      loginUsername.mutate(formData, {
        onError: (error) => {
          form.control.setError('username', {type: 'border'})
        form.control.setError('password', {type: 'border'})
        },
      })
  })

  return {
    handleSubmit,
    loginUsername,
    form,
  }
}
