
export const Theme = {
  select: [
    'h-[32px] w-[64px] text-neutral-700 text-sm bg-white border-solid border rounded-lg border-neutral-300 px-3 py-0',
    'dark:dark:bg-gray-700'
  ],

  button: [
    'flex justify-center items-center min-w-[32px] h-[32px] bg-white text-gray-500 text-sm p-1 rounded cursor-pointer',
    'enabled:hover:bg-primary-25 enabled:hover:text-primary-500',
    'disabled:cursor-default disabled:opacity-50',
    'dark:bg-gray-700',
    'dark:enabled:hover:bg-primary-25'
  ],
  buttonActive: [
    'bg-primary-25 opacity-20 border-solid border border-primary-300 text-primary-500',
    'disabled:opacity-100',
    'dark:bg-primary-600'
  ],
  buttonLoading: 'disabled:cursor-wait disabled:opacity-80',
  separator: [
    'flex justify-center items-center min-w-[32px] h-[32px] text-gray-400 text-lg p-1'
  ]
};
