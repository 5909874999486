import {createElement, useEffect, useRef} from 'react'
import {Button} from '../../Inputs'
import {Menu} from '../Menu/Menu.page'
import {MenuRef} from '../Menu/Menu.types'
import {FilterIcon} from '../../Icons'
import {FilterMenuProps} from './FilterMenu.types'
import {Stack} from '../../Layouts'
import {FilterMenuProvider, useFilterMenuContext} from './FilterMenu.context'
import {useUrlSearchParams} from 'src/app/hooks'
import {filterKeys} from 'src/app/utils/object-utils'
import _isEmpty from 'lodash/isEmpty'

export function _FilterMenu({
  buttonClassName,
  buttonProps,
  children,
  resetKey,
  keysToKeepAfterReset = [],
  defaultValuesAfterReset = {},
  ...props
}: FilterMenuProps) {
  const [searchParams, setSearchParams] = useUrlSearchParams()
  const filterMenuContext = useFilterMenuContext<{[key: string]: any}>()
  const filterMenuRef = useRef<MenuRef>(null)

  const handleApplyFilter = () => {
    const page = !searchParams.page ? undefined : '1'
    setSearchParams({...filterMenuContext.draft, page})
    filterMenuRef.current?.hide()
  }

  const transformObject = (
    input: {[key: string]: string},
    params: string[],
    defaultValues: {[key: string]: string}
  ): {[key: string]: string | undefined} => {
    const output: {[key: string]: string | undefined} = {}

    Object.keys(input).forEach((key) => {
      if (params.includes(key)) {
        output[key] = input[key]
      } else {
        output[key] = defaultValues[key]
      }
    })

    return output
  }

  const handleResetFilter = () => {
    let emptyDraft = {}

    if (keysToKeepAfterReset.length > 0 || !_isEmpty(defaultValuesAfterReset)) {
      emptyDraft = transformObject(
        filterMenuContext.draft,
        keysToKeepAfterReset,
        defaultValuesAfterReset
      )
      filterMenuContext.setDraft(emptyDraft)
    } else {
      emptyDraft = filterMenuContext.resetDraft()
    }

    filterMenuContext.setFilterCount(0)
    const filteredParams = resetKey ? filterKeys(filterMenuContext.draft, resetKey) : emptyDraft
    setSearchParams(filteredParams)
  }

  useEffect(() => {
    filterMenuContext.updateDraft({...searchParams})
  }, [JSON.stringify(searchParams)])

  return (
    <Menu
      withCaret={false}
      onHide={() => filterMenuContext.setVisible(false)}
      onShow={() => filterMenuContext.setVisible(true)}
      title='Filter'
      ref={filterMenuRef}
      id='filter-menu'
      menuButton={
        <Button
          className={`${
            filterMenuContext.filterCount > 0 ? 'bg-primary-25' : ''
          } ${buttonClassName}`}
          startIcon={FilterIcon}
          variant='outlined'
          color={filterMenuContext.filterCount > 0 ? 'primary' : 'basic'}
          {...buttonProps}
        >
          Filter{' '}
        </Button>
      }
      {...props}
    >
      {children}
      <Stack direction='row' justify='center' className='py-4' gap={2}>
        <Button color='basic' onClick={handleResetFilter} variant='outlined' className='w-1/2'>
          Reset
        </Button>
        <Button onClick={handleApplyFilter} className='w-1/2'>
          Apply
        </Button>
      </Stack>
    </Menu>
  )
}

export function FilterMenu(props: Readonly<FilterMenuProps>) {
  return <FilterMenuProvider>{createElement(_FilterMenu, props)}</FilterMenuProvider>
}
