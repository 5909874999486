import clsx from 'clsx'
import React from 'react'

const BulletItem: React.FC<{isActive: boolean}> = ({isActive}) => {
  return (
    <span className='flex items-center justify-center w-6 mr-2 shrink-0'>
      <span
        data-testid='bullet'
        className={clsx('w-1 h-1 rounded-full', 'hover:bg-primary-light-2', {
          'bg-primary dark:bg-white': isActive,
          'bg-neutral-50': !isActive,
        })}
      ></span>
    </span>
  )
}

export default BulletItem
