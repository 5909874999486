import dayjs from "dayjs";

import { Controller, useFormContext } from "react-hook-form";
import { DateObject } from "react-multi-date-picker";
import Labels from "src/app/constants/labels";
import { TimeIcon } from "../../Icons/InputIcons/TimeIcon";
import { DatePicker } from "../../Inputs";
import { useDisabledField } from "../HookForm/HookForm.context";
import { InputTimePickerProps } from "./InputTimePicker.types";

function convertValue(value?: DateObject | DateObject[] | null): any {
  if (Array.isArray(value)) {
    return value.map(convertValue);
  }
  if (!!value) {
    return dayjs(value.toString()).format('HH:mm');
  }
  return (undefined);
}

export function InputTimePicker({
  disabled,
  name,
  rule,
  ...props
}: InputTimePickerProps) {

  const isDisabled = useDisabledField(name);
  const form = useFormContext();

  const handleChange = (value: any) => {
    const _value = convertValue(value);
    form.setValue(name, _value, {
      shouldValidate: true,
      shouldTouch: true
    });
  };

  return (
    <Controller
      control={form.control}
      rules={rule}
      name={name}
      render={({ field, fieldState }) => {

        const error = fieldState.error;
        const errorMessage = rule?.required === true
          ? Labels.FIELD_REQUIRED
          : error?.message?.toString();

        return (
          <DatePicker
            {...props}
            format="HH:mm"
            disableDayPicker={true}
            inputProps={{ startIcon: TimeIcon, disabled: isDisabled || disabled, ref: field.ref }}
            helperText={!error ? props.helperText : errorMessage}
            onChange={handleChange}
            required={!!rule?.required}
            hideTime={false}
            value={!field.value ? undefined : new Date(`01/01/1970 ${field.value}`)}
            error={!!error}
          />
        );
      }}
    />
  );
}
