import clsx from 'clsx'
import { useMemo } from 'react'
import { useMasterLayout } from 'src/app/context/MasterContext'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import ProductManagementScreens from 'src/app/modules/product-management/Screens'
import BroadcastScreens from '../../../modules/broadcast/Screen'
import CampaignScreens from '../../../modules/campaign/Screen'
import CustomerScreens from '../../../modules/customer/Screen'
import GeneralConfigScreens from '../../../modules/general-config/Screen'
import UserManagementScreens from '../../../modules/user-management/Screen'
import LocationScreens from 'src/app/modules/location/Screens'
import { UserModel } from '../../../modules/user-management/models/ManageUser.model'
import { ModulePermissionQuery } from '../../../modules/user-management/models/Permission.model'
import { useGetProfile } from '../../../modules/user-management/providers/ManageUser.provider'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: UserModel): Menu[] => {
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: CustomerScreens.CUSTOMER_MANAGEMENT_LIST.PATH,
              icon: '/media/icons/IconCustomer.svg',
              title: CustomerScreens.CUSTOMER_MANAGEMENT_LIST.TITLE,
            },
            {
              to: ProductManagementScreens.PRODUCT_MANAGEMENT_LIST.PATH,
              icon: '/media/icons/IconProductManagement.svg',
              title: ProductManagementScreens.PRODUCT_MANAGEMENT_LIST.TITLE,
            },
            {
              to: CampaignScreens.CAMPAIGN.PATH,
              icon: '/media/icons/IconCampaign.svg',
              title: CampaignScreens.CAMPAIGN.TITLE,
              children: [
                {
                  to: CampaignScreens.LIST_MAIN_BANNER.PATH,
                  activePath: CampaignScreens.LIST_MAIN_BANNER.PATH,
                  title: CampaignScreens.LIST_MAIN_BANNER.TITLE,
                  hasBullet: true,
                },
                {
                  to: CampaignScreens.LIST_POPUP_BANNER.PATH,
                  activePath: CampaignScreens.LIST_POPUP_BANNER.PATH,
                  title: CampaignScreens.LIST_POPUP_BANNER.TITLE,
                  hasBullet: true,
                },
              ],
            },
            {
              to: BroadcastScreens.LIST_BROADCAST.PATH,
              icon: '/media/icons/IconBroadcast.svg',
              title: BroadcastScreens.BROADCAST.TITLE,
              module_permissions: BroadcastScreens.BROADCAST.PERMISSION,
            },
            {
              to: LocationScreens.LOCATION.PATH,
              icon: '/media/icons/IconLocation.svg',
              title: LocationScreens.LOCATION.TITLE,
              children: [
                {
                  to: LocationScreens.LIST_WORKSHOP.PATH,
                  activePath: LocationScreens.LIST_WORKSHOP.PATH,
                  title: LocationScreens.LIST_WORKSHOP.TITLE,
                  hasBullet: true,
                },
                {
                  to: LocationScreens.LIST_BRANCHES.PATH,
                  activePath: LocationScreens.LIST_BRANCHES.PATH,
                  title: LocationScreens.LIST_BRANCHES.TITLE,
                  hasBullet: true,
                },
              ],
            },
            {
              to: UserManagementScreens.MANAGE_USER.PATH,
              icon: '/media/icons/IconUser.svg',
              title: UserManagementScreens.MANAGE_USER.TITLE,
              children: [
                {
                  to: UserManagementScreens.LIST_MANAGE_ROLE.PATH,
                  activePath: UserManagementScreens.LIST_MANAGE_ROLE.PATH,
                  title: UserManagementScreens.LIST_MANAGE_ROLE.TITLE,
                  hasBullet: true,
                },
                {
                  to: UserManagementScreens.LIST_MANAGE_USER.PATH,
                  activePath: UserManagementScreens.LIST_MANAGE_USER.PATH,
                  title: UserManagementScreens.LIST_MANAGE_USER.TITLE,
                  hasBullet: true,
                },
              ],
            },
            {
              to: GeneralConfigScreens.GENERAL_CONFIG.PATH,
              icon: '/media/icons/IconSetting.svg',
              title: GeneralConfigScreens.GENERAL_CONFIG.TITLE,
              children: [
                {
                  to: GeneralConfigScreens.VIEW_TERMS_CONDITIONS.PATH,
                  activePath: GeneralConfigScreens.VIEW_TERMS_CONDITIONS.PATH,
                  title: GeneralConfigScreens.VIEW_TERMS_CONDITIONS.TITLE,
                  hasBullet: true,
                },
                {
                  to: GeneralConfigScreens.VIEW_PRIVACY_POLICY.PATH,
                  activePath: GeneralConfigScreens.VIEW_PRIVACY_POLICY.PATH,
                  title: GeneralConfigScreens.VIEW_PRIVACY_POLICY.TITLE,
                  hasBullet: true,
                },
                {
                  to: GeneralConfigScreens.LIST_FAQ.PATH,
                  activePath: GeneralConfigScreens.LIST_FAQ.PATH,
                  title: GeneralConfigScreens.LIST_FAQ.HEADER_NAME,
                  hasBullet: true,
                },
              ],
            },
          ],
        },
      ] as Menu[],
    [user]
  )
}

export const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user = useGetProfile()
  const menus = useMenus(user.data)
  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])

  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
