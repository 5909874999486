import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useActions } from "react-redux-actions-hook";

import AuthRedux from "../redux/AuthRedux";

import { LOGIN_BY_USERNAME, URL_LOGOUT, loginByUsername, logout } from "../services/Auth.services";
import { BaseResponse, Response } from "src/app/models/api.types";
import { AxiosError, AxiosResponse } from "axios";
import { AuthModel } from "../models/Auth.model";

export function useLoginByUsername<T extends string>(
  options?: UseMutationOptions<
    AxiosResponse<BaseResponse<Response<AuthModel>>>,
    AxiosError<BaseResponse<any>>,
    Record<T, string>
  >
) {

  const action = useActions({ login: AuthRedux.actions.login });

  return useMutation({
    mutationKey: [LOGIN_BY_USERNAME],
    mutationFn: loginByUsername,
    onSuccess: (response) => {
      const {
        access_token = '',
        refresh_token = ''
      } = response.data.response_output?.detail || {};
      action.login(access_token, refresh_token);
    },
    ...options
  });
}

export function useLogout<T extends string>(
  options?: UseMutationOptions<
    AxiosResponse<BaseResponse<Response<null>>>,
    AxiosError<BaseResponse<any>>,
    Record<T, string>
  >
) {

  const action = useActions({ logout: AuthRedux.actions.logout });

  return useMutation({
    mutationKey: [URL_LOGOUT],
    mutationFn: logout,
    onSuccess: (_) => action.logout(),
    ...options
  });
}
