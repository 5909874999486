import { SVGProps } from "react";

export function WarningIconBlack(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M13.77 5.362c-.75-1.424-2.79-1.424-3.54 0L3.543 18.068C2.842 19.4 3.808 21 5.313 21h13.374c1.505 0 2.471-1.6 1.77-2.932L13.77 5.362zm-3.107 4.78A1 1 0 0111.653 9h.694a1 1 0 01.99 1.141l-.766 5.364a.576.576 0 01-1.142 0l-.766-5.364zM13 18a1 1 0 11-2 0 1 1 0 012 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
