import axios, {AxiosResponse} from 'axios'
import { BaseResponse, Response } from "src/app/models/api.types";
import {AuthModel} from '../models/Auth.model'

//new
export const LOGIN_BY_USERNAME = `/api/v1/cms/auth/sign-in`
export const URL_LOGOUT = `api/v1/cms/auth/sign-out`;

//old
export const CHANGE_PASSWORD = `/api/v1/auth/change-password`
export const RESET_PASSWORD = `/api/v1/auth/forgot-password`
export const REQUEST_RESET_PASSWORD = `/api/v1/auth/forgot-password-confirmation`
export const VERIFY_RESET_PASSWORD = `/api/v1/auth/forgot-password-detail`

export function loginByUsername(data?: any): Promise<AxiosResponse<BaseResponse<Response<AuthModel>>>> {
  return axios.post(LOGIN_BY_USERNAME, data)
}

export function logout(data?: any): Promise<AxiosResponse<BaseResponse<Response<null>>>> {
  return axios.post(URL_LOGOUT, data);
}

export const changePassword = (data?: any, config?: any) => {
  return axios.post(CHANGE_PASSWORD, data, config)
}

export const requestResetPassword = (data?: any, cancelToken?: any) => {
  return axios.post(REQUEST_RESET_PASSWORD, data, {
    cancelToken,
  })
}

export const verifyResetPassword = (id: string) => {
  return axios.get(`${VERIFY_RESET_PASSWORD}/${id}`)
}

export const saveResetPassword = (id?: string, data?: any) => {
  return axios.put(`${RESET_PASSWORD}/${id}/new-password`, data)
}
