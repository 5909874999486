import {twMerge} from 'tailwind-merge'
import {Ref, createElement, forwardRef, useState} from 'react'

import {InputSizeMap, TextFieldProps} from './TextField.types'

import {EyeClose, EyeOpen} from '../../Icons/InputIcons'
import {HelperText, Label, Typography} from '../../Display'
import {Theme} from './TextField.theme'
import {DangerIconCirlce} from '../../Icons/AlertIcons/DangerIconCircle'

function _TextField(
  {
    helperText,
    className,
    startIcon,
    endIcon,
    classes,
    prefix,
    suffix,
    sizing,
    error,
    label,
    rule,
    type,
    onClickEndIcon,
    ...props
  }: TextFieldProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const isInputPassword = type === 'password'
  const hasStartIcon = !!startIcon
  const hasEndIcon = !!endIcon
  const hasPrefix = !!prefix
  const hasSuffix = !!suffix
  const hasError = !!error

  return (
    <div className={twMerge(Theme.container, classes?.container, className)}>
      {!!label && (
        <Label required={props.required} {...{error}}>
          {label}
        </Label>
      )}
      <div className={twMerge(Theme.wrapper, classes?.wrapper)}>
        {(hasStartIcon || hasPrefix) && (
          <div
            className={twMerge(
              Theme.startIconWrapper,
              classes?.startIconWrapper,
              hasPrefix && 'rounded-l-lg bg-gray-300'
            )}
          >
            {hasStartIcon &&
              createElement(startIcon, {
                className: twMerge(Theme.svgIcon, classes?.startIcon),
              })}
            {hasPrefix && <Typography className='text-gray-500 text-sm'>{prefix}</Typography>}
          </div>
        )}
        {hasError ? (
          <div className={twMerge(Theme.endIconWrapper, classes?.endIconWrapper)}>
            {createElement(DangerIconCirlce, {
              className: twMerge(Theme.svgIcon, classes?.endIcon),
            })}
          </div>
        ) : (
          <>
            {isInputPassword ? (
              <div
                onClick={() => setPasswordVisible((_) => !_)}
                className={twMerge(Theme.endIconPasswordWrapper, classes?.endIconWrapper)}
              >
                {createElement(isPasswordVisible ? EyeOpen : EyeClose, {
                  className: twMerge(Theme.svgIcon, classes?.endIcon),
                })}
              </div>
            ) : (
              (hasEndIcon || hasSuffix) && (
                <div className={twMerge(Theme.endIconWrapper, classes?.endIconWrapper)}>
                  {hasEndIcon &&
                    createElement(endIcon, {
                      className: twMerge(Theme.svgIcon, classes?.endIcon),
                      onClick: onClickEndIcon,
                    })}
                  {hasSuffix && <Typography>{suffix}</Typography>}
                </div>
              )
            )}
          </>
        )}
        <input
          type={isPasswordVisible ? 'text' : type}
          ref={forwardedRef}
          className={twMerge(
            Theme.input,
            hasStartIcon && 'pl-12',
            hasPrefix && (prefix === '+62' ? 'pl-[65px]' : 'pl-24'),
            (hasEndIcon || hasSuffix) && 'pr-10',
            hasStartIcon && hasPrefix && 'pl-20',
            hasEndIcon && hasSuffix && 'pr-20',
            !!sizing && InputSizeMap[sizing],
            error && Theme.inputError,
            classes?.input
          )}
          {...props}
        />
      </div>
      {!!helperText && <HelperText {...{error}}>{helperText}</HelperText>}
    </div>
  )
}

export const TextField = forwardRef(_TextField)
