import {
  AnimationEvent,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import {
  twMerge
} from "tailwind-merge";

import {
  Info
} from "../Info/Info.page";
import {
  PageToastProps,
  PageToastRef,
  ToastData
} from "./PageToast.types";
import {
  Theme
} from "./PageToast.theme";
import {
  useMasterLayout
} from "src/app/context/MasterContext";

const defaultStyle = {
  right: 32,
  left: 32,
  top: 64
};

const getStyle = (anchorId?: string) => {
  let anchorEl = document.getElementById('content-wrapper');
  if (!!anchorId) {
    anchorEl = document.getElementById(anchorId) || anchorEl;
  }
  let minOffsetTop = anchorEl?.id === 'content-wrapper' ? 105 : 24;
  if (!!anchorEl) {
    const viewportOffset = anchorEl.getBoundingClientRect();
    return ({
      width: viewportOffset.width,
      left: viewportOffset.left,
      top: Math.max(viewportOffset.top, minOffsetTop)
    });
  }
  return ({});
};

function _PageToast(
  _: PageToastProps,
  forwardedRef: Ref<PageToastRef>
) {

  const [visible, setVisible] = useState<boolean>(true);
  const [dismiss, setDismiss] = useState<boolean>(false);
  const [style, setStyle] = useState<{ [key: string]: any }>(defaultStyle);

  const customElementId = useRef<string | undefined>();
  const masterLayout = useMasterLayout();
  const toastData = useRef<ToastData[]>([]);

  const show = ({ elementId, ...data }: ToastData) => {
    customElementId.current = elementId;
    toastData.current.push({ ...data, timestamp: Date.now() });
    updateStyle(elementId);
    setVisible(true);
  };
  const hide = () => {
    setDismiss(true);
  };
  useImperativeHandle(
    forwardedRef,
    () => ({ show, hide }),
    []
  );

  const updateStyle = (anchorId?: string) => {
    const _style = getStyle(anchorId);
    setStyle(_style);
  };

  useEffect(() => {
    function resizeListener() {
      updateStyle(customElementId.current);
    }
    if (visible) {
      window.addEventListener('resize', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
      }
    }
  }, [visible]);

  useEffect(() => {
    function resizeListener() {
      updateStyle();
    }
    if (visible && !!customElementId.current) {
      const customEl = document.getElementById(customElementId.current);
      customEl?.addEventListener('animationstart', resizeListener);
      return () => {
        customEl?.removeEventListener('animationstart', resizeListener);
      }
    }
  }, [visible]);

  useEffect(() => {
    setTimeout(() => {
      updateStyle();
    }, 300);
  }, [masterLayout.minimize]);

  const handleToastHide = (item: ToastData) => {
    const _toasts = toastData.current.filter((_) => _.timestamp !== item.timestamp);
    if (_toasts.length > 0) {
      toastData.current = _toasts;
    } else {
      hide();
    }
  };
  const renderItem = (item: ToastData) => {
    return (
      <Info
        severity={item.severity}
        bordered={true}
        autoHide={true}
        onHide={() => handleToastHide(item)}
        key={item.timestamp}
      >
        {item.message}
      </Info>
    );
  }

  const handleAnimEnd = (event: AnimationEvent<HTMLDivElement>) => {
    switch (event.animationName) {
      case 'modal-open':
        break;

      case 'modal-close':
        toastData.current = [];
        setVisible(false);
        setDismiss(false);
        break;
    }
  };

  return (visible) ? (
    <div
      onAnimationEnd={handleAnimEnd}
      className={twMerge(
        Theme.container,
        dismiss ? "animate-modal-close" : "animate-modal-open"
      )}
      {...{ style }}
    >
      {toastData.current.map(renderItem)}
    </div>
  ) : null;
}

export const PageToast = forwardRef(_PageToast);
