const CustomerSurveyScreens = {
  CUSTOMER_SURVEY: {
    PATH: '/customer-survey',
    TITLE: 'Customer Survey',
    TITLE_ALT: 'Customer Survey',
  },
  LIST_GENERAL_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/general',
    HEADER_NAME: 'List Survey Creation',
    TITLE: 'Survey Creation',
    TITLE_ALT: 'Survey Creation',
    PERMISSION: 'customer_survey_creation.read',
  },
  DETAIL_GENERAL_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/general/detail/:id',
    TITLE: 'Survey Creation Detail',
    TITLE_ALT: 'Survey Creation Detail',
    PERMISSION: 'customer_survey_creation.read',
  },
  ADD_GENERAL_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/general/add',
    TITLE: 'Create Survey',
    TITLE_ALT: 'Create Survey',
    PERMISSION: 'customer_survey_creation.create',
  },
  EDIT_GENERAL_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/general/edit/:id',
    TITLE: 'Edit Survey',
    TITLE_ALT: 'Edit Survey',
    PERMISSION: 'customer_survey_creation.update',
  },
  LIST_CLAIM_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/claim',
    HEADER_NAME: 'List Survey Creation',
    TITLE: 'Survey Creation',
    TITLE_ALT: 'Survey Creation',
    PERMISSION: 'customer_survey_creation.read',
  },
  DETAIL_CLAIM_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/claim/detail/:id',
    TITLE: 'Survey Creation Detail',
    TITLE_ALT: 'Survey Creation Detail',
    PERMISSION: 'customer_survey_creation.read',
  },
  ADD_CLAIM_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/claim/add',
    TITLE: 'Create Survey',
    TITLE_ALT: 'Create Survey',
    PERMISSION: 'customer_survey_creation.create',
  },
  EDIT_CLAIM_SURVEY_CREATION: {
    PATH: '/customer-survey/survey-creation/claim/edit/:id',
    TITLE: 'Edit Survey',
    TITLE_ALT: 'Edit Survey',
    PERMISSION: 'customer_survey_creation.update',
  },
  // RESPONSE --
  LIST_GENERAL_SURVEY_RESPONSE: {
    PATH: '/customer-survey/survey-response/general',
    HEADER_NAME: 'List Survey Response',
    TITLE: 'Survey Response',
    TITLE_ALT: 'Survey Response',
    PERMISSION: 'customer_survey_response.read',
  },
  DETAIL_GENERAL_SURVEY_RESPONSE: {
    PATH: '/customer-survey/survey-response/general/detail/:id/:response_type',
    TITLE: 'Survey Response Detail',
    TITLE_ALT: 'Survey Response Detail',
    PERMISSION: 'customer_survey_response.read',
  },
  LIST_CLAIM_SURVEY_RESPONSE: {
    PATH: '/customer-survey/survey-response/claim',
    HEADER_NAME: 'List Survey Response',
    TITLE: 'Survey Response',
    TITLE_ALT: 'Survey Response',
    PERMISSION: 'customer_survey_response.read',
  },
  DETAIL_CLAIM_SURVEY_RESPONSE: {
    PATH: '/customer-survey/survey-response/claim/detail/:id/:response_type',
    TITLE: 'Survey Response Detail',
    TITLE_ALT: 'Survey Response Detail',
    PERMISSION: 'customer_survey_response.read',
  },
}

export default CustomerSurveyScreens
