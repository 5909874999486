import {
  ChangeEvent,
  Ref,
  forwardRef,
  useLayoutEffect,
  useState
} from "react";
import {
  twMerge
} from "tailwind-merge";

import {
  Theme
} from "./Toggle.theme";
import {
  ToggleItemType,
  ToggleProps
} from "./Toggle.types";

function _Toggle(
  {
    onChange = () => { },
    extraOnChange = () => { },
    toggle = [],
    value,
    ...props
  }: ToggleProps,
  _: Ref<HTMLInputElement>
) {

  const [toggleData, setToggleData] = useState<ToggleItemType>({} as ToggleItemType);

  const hasToggle = toggle.length === 2;

  useLayoutEffect(() => {
    if (hasToggle) {
      if (value === undefined) {
        setToggleData(toggle[1]);
      } else {
        const _toggleData = toggle.find((_) => _.value === value);
        if (!!_toggleData) {
          setToggleData(_toggleData);
        }
      }
    }
  }, [value]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (hasToggle && !props.readOnly) {
      const _toggleData = (event.target.checked) ? toggle[0] : toggle[1];
      event.target.value = _toggleData?.value?.toString();
      setToggleData(_toggleData);
      onChange(event);
      extraOnChange(event);
    }
  };

  return (
    <label className={twMerge(Theme.container, props.disabled ? 'cursor-not-allowed':'cursor-pointer')}>
      <input
        {...props}
        className="sr-only peer"
        onChange={handleInputChange}
        checked={hasToggle ? toggle[0]?.value === toggleData?.value : undefined}
        value={toggleData?.value?.toString()}
        type='checkbox'
      />
      <div className={twMerge(Theme.toggle)} />
      {!!toggleData?.label && <span className={twMerge(Theme.label)}>{toggleData?.label}</span>}
    </label>
  )
}

export const Toggle = forwardRef(_Toggle);
