import {SVGProps} from 'react'
import {twMerge} from 'tailwind-merge'

export function DetailIcon({className, ...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7804 5.12929C16.2032 6.22748 17.4146 7.83636 18.2845 9.84849C18.3495 10.0012 18.3495 10.1798 18.2845 10.3253C16.5446 14.3495 13.4471 16.7576 9.99993 16.7576H9.9918C6.55277 16.7576 3.45521 14.3495 1.71538 10.3253C1.65034 10.1798 1.65034 10.0012 1.71538 9.84849C3.45521 5.82344 6.55277 3.42424 9.9918 3.42424H9.99993C11.7235 3.42424 13.3577 4.02222 14.7804 5.12929ZM6.7479 10.0909C6.7479 11.8687 8.20318 13.3152 9.99993 13.3152C11.7885 13.3152 13.2438 11.8687 13.2438 10.0909C13.2438 8.30505 11.7885 6.85859 9.99993 6.85859C8.20318 6.85859 6.7479 8.30505 6.7479 10.0909Z'
        fill='#004A99'
      />
      <path
        d='M12.0257 10.0883C12.0257 11.1954 11.1151 12.1004 10.0013 12.1004C8.87935 12.1004 7.96878 11.1954 7.96878 10.0883C7.96878 9.95095 7.98504 9.82247 8.00943 9.69317H8.05008C8.95252 9.69317 9.68423 8.98206 9.71675 8.09237C9.80618 8.07701 9.90374 8.06812 10.0013 8.06812C11.1151 8.06812 12.0257 8.97317 12.0257 10.0883Z'
        fill='#004A99'
      />
    </svg>
  )
}
