import { Ref, forwardRef, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { twMerge } from 'tailwind-merge'

import { hasArray } from 'src/app/utils/array-utils'
import { humanFileSize } from 'src/app/utils/input-utils'

import { ActionButton, HelperText, Label, Thumbnail, ThumbnailRatio, Typography } from '../../Display'
import { FileUploadPlaceholderIcon } from '../../Icons'
import { Stack } from '../../Layouts'
import { Button } from '../Button/Button.page'
import { getErrorRejection } from '../FileUpload/FileUpload.utils'
import { Theme } from './ImageUpload.theme'
import { ImageUploadProps } from './ImageUpload.types'

function _ImageUpload(
  {
    onDeleteImage,
    onChangeImage,
    placeholder,
    buttonText,
    helperText,
    className,
    required,
    ratio,
    value,
    error,
    label,
    showActionButton = true,
    showRemoveButton = false,
    onRemove,
    ...props
  }: ImageUploadProps,
  forwardedRef: Ref<HTMLDivElement>
) {
  const [rejectReations, setRejectReations] = useState<string[]>([])
  const [fileUrl, setFileUrl] = useState<string | undefined>(value)

  const isError = rejectReations.length > 0 || error
  const hasFile = !!fileUrl

  useEffect(() => {
    if (value !== undefined) {
      setFileUrl(value)
    }
  }, [value])

  const handleAcceptedFiles = useCallback(([file]: File[]) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const _fileUrl = reader.result?.toString()
      setRejectReations([])
      onChangeImage?.(_fileUrl)
      setFileUrl(_fileUrl)
    }
  }, [])

  const dropzone = useDropzone({
    ...props,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileUrl(undefined)
      if (hasArray(fileRejections)) {
        const errorRejection = getErrorRejection(fileRejections, props)
        setRejectReations(errorRejection)
      } else {
        handleAcceptedFiles(acceptedFiles)
      }
    },
  })

  const handleDeleteImage = () => {
    setFileUrl(undefined)
    onDeleteImage?.()
  }

  const renderRejectReasonItem = (item: string) => {
    return (
      <HelperText error={isError} key={item}>
        {item}
      </HelperText>
    )
  }

  return (
    <div className='relative' ref={forwardedRef}>
      {!!label && (
        <Label error={isError} {...{required}}>
          {label}
        </Label>
      )}
      <div
        className={twMerge(
          Theme.placeholer,
          props.disabled && 'cursor-not-allowed',
          isError && Theme.placeholerError,
          hasFile && 'hidden',
          ratio && ThumbnailRatio[ratio],
          className
        )}
        {...dropzone.getRootProps()}
      >
        <FileUploadPlaceholderIcon />
        <Typography variant='caption' className='text-center'>
          {!placeholder && typeof props?.maxSize === 'number'
            ? `Photo size must be less than ${humanFileSize(props.maxSize)}`
            : placeholder}
        </Typography>
        {showActionButton && (
          <Button disabled={props.disabled} variant='outlined'>
            {buttonText}
          </Button>
        )}
        <input {...dropzone.getInputProps()} />
      </div>
      <div className={twMerge('relative', !hasFile && 'hidden')}>
        <Thumbnail
          className={twMerge('min-h-[64px]', ratio && ThumbnailRatio[ratio])}
          src={fileUrl}
        />
        {!props.disabled && (
            <Stack className='absolute bottom-4 left-4' direction='row' gap={2}>
            <ActionButton className='shadow-lg bg-warning-100' onClick={() => dropzone.open()} variant='editBB' />
            <ActionButton
              className='shadow-lg bg-danger-100'
              onClick={handleDeleteImage}
              variant='deleteBB'
            />
            </Stack>
        )}
      </div>
      {showRemoveButton && (
        <ActionButton
          className='shadow-lg !absolute top-4 right-4'
          onClick={onRemove}
          variant='close'
        />
      )}
      {rejectReations.length > 0 ? (
        <>{rejectReations.map(renderRejectReasonItem)}</>
      ) : (
        <>{!!helperText && renderRejectReasonItem(helperText)}</>
      )}
    </div>
  )
}

const ImageUpload = forwardRef(_ImageUpload)

ImageUpload.defaultProps = {
  buttonText: 'Select Photo',
} as ImageUploadProps

export { ImageUpload }

