import React from 'react'
import AsideMenuData from './AsideMenuData'
import {toAbsoluteUrl} from '../../../utils/asset-helpers-utils'

const AsideMenu: React.FC = () => {
  return (
    <div className='pb-20 mt-5 mb-20 overflow-y-scroll no-scrollbar'>
      <AsideMenuData />
      <div className='absolute left-0 top-0 -z-10'>
        <img src={toAbsoluteUrl('/media/icons/sidebar-assets.png')} alt='sidebar-assets' className='' />
      </div>
    </div>
  )
}

export {AsideMenu}
