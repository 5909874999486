const AuditTrailScreens = {
  AUDIT_TRAIL: {
    PATH: '/audit-trail',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
  },
  LIST_USER_ACTIVITY: {
    PATH: '/audit-trail/user-activity',
    HEADER_NAME: 'List User Activity',
    TITLE: 'User Activity',
    TITLE_ALT: 'User Activity',
    PERMISSION: 'audit_trail_user_activity.read',
  },
  LIST_SYSTEM_ACTIVITY: {
    PATH: '/audit-trail/system-activity',
    HEADER_NAME: 'List System Activity',
    TITLE: 'System Activity',
    TITLE_ALT: 'System Activity',
    PERMISSION: 'audit_trail_system_activity.read',
  },
}

export default AuditTrailScreens
