import { Editor } from "@tinymce/tinymce-react";
import { twMerge } from "tailwind-merge";

import { TextEditorProps } from "./TextEditor.types";
import { HelperText, Label } from "../../Display";
import { Theme } from "./TextEditor.theme";

const pluginsConfig = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount'
];

const toolbarConfig = [
  'undo redo',
  'formatselect',
  'bold italic backcolor',
  'alignleft aligncenter alignright alignjustify',
  'bullist numlist outdent indent',
  'removeformat',
  'code',
  'link',
  'help',
].join(' | ');

export function TextEditor({
  initialValue,
  placeholder,
  helperText,
  className,
  onChange,
  required,
  value,
  label,
  error,
  disabled,
  ref,
  onInit,
}: TextEditorProps) {
  return (
    <div className={twMerge("relative", className)}>
      {(!!label) && <Label {...{ required, error }}>{label}</Label>}
      <div
        className={twMerge(error && Theme.inputError, Theme.input)}
        ref={ref}
      >
        <Editor
          disabled={disabled}
          onEditorChange={(_) => onChange?.(_)}
          onInit={onInit}
          init={{
            placeholder,
            height: 280,
            menubar: false,
            plugins: pluginsConfig,
            toolbar: toolbarConfig,
            content_style: 'body { font-family:Manrope, sans-serif; font-size:14px }'
          }}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          {...{
            initialValue,
            value
          }}
        />
      </div>
      {error && <HelperText {...{ error }}>{error}</HelperText>}
      {(!!helperText) && <HelperText {...{ error }}>{helperText}</HelperText>}

    </div>
  );
}