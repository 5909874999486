import {
  useFormContext
} from "react-hook-form";
import {
  ChangeEvent
} from "react";

import {
  TextField
} from "../../Inputs";
import {
  InputTextFieldProps
} from "./InputTextField.types";
import {
  cleanNumber,
  formatCurrency,
  getHelperTextMessage,
  getInputType,
  validateForm
} from "./InputTextField.utils";
import {
  useDisabledField,
} from "../HookForm/HookForm.context";

export function InputTextField({
  disabled,
  name,
  rule,
  ...props
}: InputTextFieldProps) {

  const isCurrency = props.type === 'currency';

  const isDisabled = useDisabledField(name);
  const form = useFormContext();
  const error = form.formState.errors[name];

  const setValueAs = (value?: string) => {
    if (props.type === 'number') {
      if (value !== undefined) {
        return isNaN(+value) ? undefined : +value;
      }
    }
    if (isCurrency) {
      return cleanNumber(value);
    }
    return (value);
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // reset error when user type
    form.clearErrors(name);
    if (isCurrency) {
      formatCurrency(event.target);
    }
  };

  const register = form.register(name, {
    validate: validateForm(props.type, rule?.required),
    setValueAs,
    onChange,
    ...rule
  });

  const handleRef = (ref: HTMLInputElement | null) => {
    register.ref(ref);
    if (isCurrency) {
      setTimeout(() => formatCurrency(ref), 100);
    }
  };

  return (
    <TextField
      {...{ ...props, ...register }}
      helperText={props.helperText ?? getHelperTextMessage(error, props)}
      required={!!rule?.required}
      type={getInputType(props.type)}
      onWheel={(e) => e?.currentTarget?.blur()}
      error={props.error ?? !!error}
      ref={handleRef}
      disabled={isDisabled || disabled}
    />
  );
}
