import LoginUsername from './page/Login/Login'

const AuthScreens = {
  LOGIN: {
    COMPONENT: LoginUsername,
    PATH: '/auth/login',
  },
}

export default AuthScreens
