import {FC, useEffect, useRef, useState} from 'react'
import {ConnectedProps, connect, shallowEqual, useDispatch, useSelector} from 'react-redux'
import {LayoutSplashScreen} from 'src/app/context/SplashScreenContext'
import {RootState} from 'src/setup'
import AuthRedux from './AuthRedux'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, AuthRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  children?: React.ReactNode
}

const AuthInit: FC<Props> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const user = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  useEffect(() => {
    if (user) {
      didRequest.current = true
      setShowSplashScreen(false)
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
