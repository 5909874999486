import { Children, createElement, isValidElement } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import React from 'react'
import { Typography } from '../../Display'
import { Stack } from '../../Layouts'
import { Theme } from './ContentAction.theme'
import { ContentActionProps } from './ContentAction.types'

export function ContentAction({
  children,
  hideCancelButton,
  hideSubmitButton,
  mutation,
  loading,
  action,
  form,
  title,
  ...props
}: ContentActionProps) {
  const history = useHistory()

  const [cancelEl, submitEl] = Children.toArray(children)

  const isLoading = mutation?.isLoading || loading

  return (
    <Stack
      className={twMerge(Theme.container)}
      direction='row'
      justify='between'
      gap={4}
      {...props}
    >
      <>
        <Typography variant='h5' className='flex-1'>
          {title}
        </Typography>
        {isValidElement(cancelEl) &&
          !hideCancelButton &&
          cancelEl.type !== React.Fragment &&
          createElement(cancelEl.type, {
            disabled: isLoading,
            children: 'Cancel',
            onClick: () => history.goBack(),
            color: 'primary',
            ...cancelEl.props,
            className: twMerge('min-w-[128px]'),
          })}
        {isValidElement(submitEl) &&
          !hideSubmitButton &&
          submitEl.type !== React.Fragment &&
          createElement(submitEl.type, {
            children: 'Submit',
            onClick: form?.handleSubmit((_) => mutation?.mutate(_)),
            loading: isLoading,
            color: 'primary',
            ...submitEl.props,
            className: twMerge('min-w-[128px]'),
            variant: 'contained',
          })}
      </>
    </Stack>
  )
}
