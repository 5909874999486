const BroadcastScreens = {
  BROADCAST: {
    PATH: '/broadcast',
    TITLE: 'Broadcast',
    TITLE_ALT: 'Broadcast',
    PERMISSION: 'broadcast.read',
  },
  LIST_BROADCAST: {
    PATH: '/broadcast/list',
    HEADER_NAME: 'List Broadcast',
    TITLE: 'List Broadcast',
    TITLE_ALT: 'List Broadcast',
  },
  ADD_BROADCAST: {
    PATH: '/broadcast/add',
    HEADER_NAME: 'Create Broadcast',
    TITLE: 'Create Broadcast',
    TITLE_ALT: 'Create Broadcast',
  },
  EDIT_BROADCAST: {
    PATH: '/broadcast/edit/:id',
    HEADER_NAME: 'Edit Broadcast',
    TITLE: 'Edit Broadcast',
    TITLE_ALT: 'Edit Broadcast',
  },
  DETAIL_BROADCAST: {
    PATH: '/broadcast/detail/:id',
    HEADER_NAME: 'Detail Broadcast',
    TITLE: 'Detail Broadcast',
    TITLE_ALT: 'Detail Broadcast',
  },
  TARGET_CONTENT_BROADCAST: {
    PATH: '/broadcast/add/:step',
    HEADER_NAME: 'Target Content Broadcast',
    TITLE: 'Target Content Broadcast',
    TITLE_ALT: 'Target Content Broadcast',
  },
  EDIT_TARGET_CONTENT_BROADCAST: {
    PATH: '/broadcast/edit/:id/:step',
    HEADER_NAME: 'Target Content Broadcast',
    TITLE: 'Target Content Broadcast',
    TITLE_ALT: 'Target Content Broadcast',
  }
}

export default BroadcastScreens
