import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {
  BaseResponse,
  Pagination,
  Response,
  ResponseFieldError,
  ResponseList,
} from 'src/app/models/api.types'
import {
  deleteUser,
  getManageUser,
  getManageUserDetail,
  getProfile,
  getSearchLDAPUser,
  saveManageUser,
  URL_USER,
} from '../services/ManageUser.service'
import {useApiParams} from '../../../hooks'
import {UserModel} from '../models/ManageUser.model'
import {createSelector} from 'reselect'

// Define selector
const selectUser = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<UserModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<UserModel[]>>) => data.response_output?.list
)

const selectProfile = createSelector(
  (state: AxiosResponse<BaseResponse<Response<UserModel>>>) => state.data,
  (data: BaseResponse<Response<UserModel>>) => data.response_output?.detail
)

const selectUserDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<UserModel>>>) => state.data,
  (data: BaseResponse<Response<UserModel>>) => {
    const {...rest} = data.response_output?.detail ?? {}

    return {
      ...rest,
    }
  }
)

// Refetch query
const useRefetchUser = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({queryKey: [URL_USER]})
  }
}

export function useGetProfile() {
  return useQuery({
    queryKey: [URL_USER],
    queryFn: getProfile,
    select: selectProfile,
  })
}

export const useGetManageUser = () => {
  const defaultParams = {
    role_name: '',
  }
  const [params] = useApiParams({defaultValues: defaultParams})

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<UserModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<UserModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_USER, params],
    queryFn: () => getManageUser(params),
    select: selectUser,
  })
}

export const useGetManageUserDetail = (id?: string) => {
  return useQuery<
    AxiosResponse<BaseResponse<Response<UserModel>>>,
    AxiosError<BaseResponse<Response<any>>>,
    UserModel | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_USER}/${id}`],
    queryFn: () => getManageUserDetail(id!),
    select: selectUserDetail,
    enabled: !!id,
  })
}

// Save User
export const useSaveManageUser = () => {
  const refetchUser = useRefetchUser()
  return useMutation<
    AxiosResponse<BaseResponse<Response<UserModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    UserModel
  >({
    mutationKey: [URL_USER],
    mutationFn: saveManageUser,
    onSuccess: refetchUser,
  })
}

// search LDAP
export const useSearchLDAPProvider = () => {
  return useMutation<
    AxiosResponse<BaseResponse<Response<UserModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    UserModel | undefined
  >({
    mutationKey: [`${URL_USER}/find-ldap`],
    mutationFn: (_) => getSearchLDAPUser(_),
  })
}

// Delete User
export const useDeleteUser = (id?: string) => {
  const refetchUser = useRefetchUser()
  return useMutation<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<Response<any>>>,
    {} | undefined
  >({
    mutationKey: [`${URL_USER}/${id}`],
    mutationFn: () => deleteUser(id!),
    onSuccess: refetchUser,
  })
}
