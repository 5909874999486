import React, {ChangeEvent, Children, isValidElement, ReactNode} from 'react'
import Labels from 'src/app/constants/labels'
import {InputGroup} from '../../Inputs/InputGroup/InputGroup.page'
import {useDisabledField} from '../HookForm/HookForm.context'
import {InputRadioGroupProps} from './InputRadioGroup.types'

export function InputRadioGroup({
  helperText,
  children,
  name,
  rule,
  form,
  ...props
}: InputRadioGroupProps) {
  const isDisabled = useDisabledField(name) || props.disabled
  const fieldState = form.getFieldState(name)
  const value = form.watch(name)?.toString()

  // Handle required field
  const isInputRequired = !!rule?.required && !value
  const error = form.formState.errors?.[name]
  const errorMessage = rule?.required === true ? Labels.FIELD_REQUIRED : error?.message?.toString()

  // Handle input change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    form.setValue(name, event.target.value, {shouldValidate: true})
    form.clearErrors(name)
  }

  // Normalize values (e.g., "true" -> true, "false" -> false)
  const setValueAs = (value?: string) => {
    if (value === 'true') return true
    if (value === 'false') return false
    return value
  }

  return (
    <InputGroup
      helperText={fieldState.invalid ? errorMessage : helperText}
      required={!!rule?.required}
      error={fieldState.invalid}
      {...props}
    >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            ...form.register(name, {
              ...rule,
              required: isInputRequired,
              setValueAs,
            }),
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              child.props.onChange?.(event) // Preserve child's onChange if exists
              handleInputChange(event)
            },
            checked: value === child.props.value,
            key: child.key,
            disabled: isDisabled,
          })
        }
        return child
      })}
    </InputGroup>
  )
}
