
export const Theme = {
  label: [
    'block mb-3 text-sm font-medium text-gray-900',
    'dark:text-white'
  ],
  labelError: [
    'text-danger'
  ]
};
