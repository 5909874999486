import { Controller } from 'react-hook-form'
import { DateObject } from 'react-multi-date-picker'
import Labels from 'src/app/constants/labels'
import { DatePicker, getDatePickerValue } from '../../Inputs'
import { useDisabledField } from '../HookForm/HookForm.context'
import { InputDatePickerProps } from './InputDatePicker.types'

export function InputDatePicker({
                                  disabled,
                                  form,
                                  name,
                                  rule,
                                  format = 'DD/MM/YYYY',
                                  onChange, // Add onChange prop
                                  ...props
                                }: InputDatePickerProps) {

  const handleChange = (value: DateObject | DateObject[] | null) => {
    const _value = getDatePickerValue(value, props.hideTime === false)
    form.setValue(name, _value, {
      shouldValidate: true,
      shouldTouch: true,
    })
    // Call the parent's onChange prop to pass the value up
    if (onChange) {
      onChange(_value)
    }
  }

  const isDisabled = useDisabledField(name)

  return (
    <Controller
      control={form.control}
      rules={rule}
      name={name}
      render={({field, fieldState}) => {

        const dateValue = new DateObject(field.value)
        const value = (!!field.value && dateValue.isValid) ? dateValue : undefined
        const error = fieldState.error
        const errorMessage = !error?.message && rule?.required === true
          ? Labels.FIELD_REQUIRED
          : error?.message?.toString()

        return (
          <DatePicker
            {...props}
            inputProps={{disabled: isDisabled || disabled, ref: field.ref}}
            format={format}
            helperText={!error ? props.helperText : errorMessage}
            onChange={handleChange}
            required={!!rule?.required}
            value={value}
            error={!!error}
          />
        )
      }}
    />
  )
}
