import React from 'react'
import {Chip} from './Chip.page'

type ChipStatusProps = {
  state?: string
}

export const ChipStatus = ({state}: ChipStatusProps) => {
  if (!state) return null
  switch (state.toLowerCase()) {
    case 'active':
      return <Chip variant='success'>Active</Chip>
    case 'suspend':
      return <Chip variant='dark-gray'>Account Suspended</Chip>
    case 'unverified':
      return <Chip variant='warning'>Need Verification</Chip>
    case 'reject':
      return <Chip variant='danger'>ID Rejected</Chip>
    case 'deleted':
      return <Chip variant='gray'>Account Deleted</Chip>
    case 'inactive':
      return <Chip variant='gray'>Inactive</Chip>
    case 'pending':
      return <Chip variant='warning'>Pending</Chip>
    case 'sent':
      return <Chip variant='gray'>Sent</Chip>
    case 'approved':
      return <Chip variant='success'>Approved</Chip>
    case 'under_review':
      return <Chip variant='info'>Under Review</Chip>
    case 'not_applied':
      return <Chip variant='danger'>Not Applied</Chip>
    case 'rejected':
      return <Chip variant='danger'>Rejected</Chip>
    case 'used':
      return <Chip variant='gray'>Used</Chip>
    case 'redeemed':
      return <Chip variant='warning'>Redeemed</Chip>
    case 'available':
      return <Chip variant='success'>Available</Chip>
    case 'scheduled':
      return <Chip variant='info'>Scheduled</Chip>
    case 'reguler':
      return <Chip variant='success'>Reguler</Chip>
    case 'special':
      return <Chip variant='info'>Special</Chip>
    case 'warning':
      return <Chip variant='danger'>Warning</Chip>
    case 'claimed':
      return <Chip variant='warning'>Claimed</Chip>
    case 'expired':
      return <Chip variant='danger'>Expired</Chip>
    case 'void':
      return <Chip variant='danger'>Void</Chip>
    case 'used promo':
      return <Chip variant='gray'>Used Promo</Chip>
    case 'request':
      return <Chip variant='gray'>Requested</Chip>
    case 'success':
      return <Chip variant='success'>Success</Chip>
    case 'login':
      return <Chip variant='info'>Login</Chip>
    case 'logout':
      return <Chip variant='info'>Logout</Chip>
    case 'edited':
      return <Chip variant='warning'>Edited</Chip>
    case 'new request':
      return <Chip variant='success'>New Request</Chip>
    case 'on progress':
      return <Chip variant='warning'>On Progress</Chip>
    case 'request done':
      return <Chip variant='gray'>Request Done</Chip>
    case 'existing':
      return <Chip variant='gray'>Existing</Chip>
    default:
      return <Chip variant='success'>Active</Chip>
  }
}

export default ChipStatus
