export const Theme = {
  th: 'ws:px-2 px-4 ws:py-2 py-4 font-medium',
  tr: 'ws:px-2 px-4 ws:py-2 py-5',
  action: [
    'text-center sticky right-0 bg-inherit rounded-r',
    'dark:border-gray-700'
  ],
  index: 'text-center w-[84px] p-3',
}

export const SizeVariant = {
  md: 'h-auto',
  lg: 'h-[93px]',
}
