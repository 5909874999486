import {HookFormProvider, InputTextField} from '../../../../components/HookForm'
import {useLogin} from './Login.hooks'
import {Button, Info, Stack} from '../../../../components'
import {PasswordIcon} from '../../../../components/Icons'

function LoginUsername() {
  const state = useLogin()
  const errorMessage = state.loginUsername.error?.response?.data.response_schema?.response_message?.en || 'Something went wrong'
  const errorCode = state.loginUsername.error?.response?.data.response_schema?.response_code || '200'

  return (
    <div data-testid='login-page' className='w-full'>
      <div className='mb-12 text-center'>
        <div className='mb-4 font-medium text-fs-5'>Login to your account</div>
        <div className='text-neutral-80'>Welcome back! Please enter your details</div>
      </div>

      <HookFormProvider form={state.form}>
        <Stack direction='col' justify='center' items='basline' gap={6}>
          {errorCode !== '200' && (
            <Info severity='danger'>
              {errorMessage}
            </Info>
          )}

          <InputTextField
            placeholder='Enter your email'
            label='NIP/Username'
            rule={{required: 'NIP/Username cannot be empty'}}
            name='username'
            type='text'
          />
          <InputTextField
            placeholder='Enter your password'
            startIcon={PasswordIcon}
            rule={{required: 'Password cannot be empty'}}
            label='Password'
            type='password'
            name='password'
          />
          <Button
            loading={state.loginUsername.isLoading}
            onClick={state.handleSubmit}
            size='lg'
            className='w-full'
            color='primary'
            variant='contained'
          >
            Login
          </Button>
        </Stack>
      </HookFormProvider>
    </div>
  )
}

export default LoginUsername
