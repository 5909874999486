import { SVGProps } from "react";
import { twMerge } from "tailwind-merge";

export function InfoIcon({
  className,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={twMerge("fill-info-900", className)}
      {...props}
    >
      <path
        d="M12 2.25A9.75 9.75 0 1021.75 12 9.76 9.76 0 0012 2.25zm-.75 5.25a.75.75 0 111.5 0v5.25a.75.75 0 11-1.5 0V7.5zm.75 9.75A1.125 1.125 0 1112 15a1.125 1.125 0 010 2.25z"
      ></path>
    </svg>
  );
}
