import {useHistory} from 'react-router-dom'
import {twMerge} from 'tailwind-merge'
import {createElement, MouseEvent} from 'react'

import {ActionButtonProps} from './ActionButton.types'
import {ButtonSize, Theme} from './ActionButton.theme'
import {DeleteIcon, DeleteIconBB, DetailIcon, EditIcon, EditIconBB} from '../../Icons/ActionIcons'
import {LoadingIcon} from '../../Icons'
import {CloseIcon} from '../../Icons/InputIcons/CloseIcon'

const iconEl = {
  detail: DetailIcon,
  edit: EditIcon,
  editBB: EditIconBB,
  delete: DeleteIcon,
  deleteBB: DeleteIconBB,
  close: CloseIcon,
}

function ActionButton({
  className,
  children,
  disabled,
  onClick,
  loading,
  variant,
  size,
  to,
  ...props
}: ActionButtonProps) {
  const history = useHistory()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!to) {
      onClick?.(event)
    } else {
      history.push(to)
    }
  }

  return (
    <button
      disabled={disabled || loading}
      onClick={handleClick}
      className={twMerge(
        Theme.button,
        ButtonSize[size],
        loading && 'disabled:cursor-wait',
        className
      )}
      {...props}
    >
      {loading
        ? createElement(LoadingIcon, {className: twMerge(Theme.loadingIcon)})
        : createElement(iconEl[variant])}
    </button>
  )
}

ActionButton.defaultProps = {
  variant: 'detail',
  size: 'md',
} as ActionButtonProps

export {ActionButton}
