export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  if (!pathname || !url) {
    return false;
  }

  // Pastikan hanya mencocokkan bagian penting dari path
  const currentParts = pathname.split('/').filter(Boolean);
  const urlParts = url.split('/').filter(Boolean);

  // Cek apakah semua bagian `url` ada di `pathname` secara berurutan
  return urlParts.every((part, index) => {
    return part.startsWith(':') || currentParts[index] === part;
  });
}
