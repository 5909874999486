import React from 'react'
import {Card} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div
      data-testid='content-wrapper-component'
      className='flex items-center justify-center w-screen h-screen py-24 bg-[#F5FAFF]'
    >
      <div className='h-max-[528px] w-[480px] z-20'>
        <Card.Root className='flex items-center justify-between h-full py-16 rounded-lg bg-[#E3F1FF99]'>
          <img src={toAbsoluteUrl('/BCA_Insurance_logo.svg')} alt='AIT' className='h-8 mb-16' />

          <div className='w-[356px]'>{children}</div>
        </Card.Root>
      </div>
      <div className='absolute bottom-0 w-full z-0'>
        <svg
          width='1440'
          height='766'
          viewBox='0 0 1440 766'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className="w-full"
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-271.833 1337.36C-447.869 1195.83 -603.951 1041.43 -603.509 874.338C-602.996 680.73 -556.204 441.072 -269.297 379.079C14.4153 317.775 283.179 561.638 593.719 615.647C897.626 668.502 1258.38 538.083 1482.13 683.687C1708.96 831.295 1616.07 1055.52 1546.07 1235.38C1482.28 1399.29 1352.14 1550.51 1114.66 1627.86C887.48 1701.84 601.517 1685.21 335.804 1629.54C88.747 1577.78 -109.626 1467.77 -271.833 1337.36Z'
            fill='#F1C400'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-258.289 1389.74C-438.51 1253.58 -599.175 1103.96 -603.77 936.929C-609.093 743.393 -569.546 502.434 -284.638 431.821C-2.90228 361.994 273.09 597.644 585.116 642.269C890.478 685.94 1247.14 544.706 1475.18 683.499C1706.35 824.203 1620.27 1051.13 1555.72 1233.02C1496.9 1398.77 1371.38 1553.85 1136.34 1638.32C911.484 1719.12 625.149 1711.11 357.879 1663.48C109.374 1619.19 -92.2245 1515.21 -258.289 1389.74Z'
            fill='#00A8B7'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-244.658 1438.48C-428.366 1307.06 -592.875 1161.67 -601.82 994.819C-612.185 801.487 -578.929 559.58 -295.958 481.567C-16.1374 404.423 265.902 632.802 578.985 669.281C885.38 704.981 1238.24 554.502 1469.82 687.306C1704.58 821.939 1624.44 1051.03 1564.65 1234.54C1510.17 1401.77 1388.73 1560.07 1155.97 1650.63C933.3 1737.26 646.854 1736.72 378.433 1696.06C128.859 1658.26 -75.3807 1559.57 -244.658 1438.48Z'
            fill='#004A99'
          />
        </svg>
      </div>
    </div>
  )
}

export default GuestWrapper
