const ProductManagementScreens = {
  PRODUCT_MANAGEMENT: {
    PATH: '/product-management',
    TITLE: 'Product Management',
    TITLE_ALT: 'Product Management',
  },
  PRODUCT_MANAGEMENT_LIST: {
    PATH: '/product-management/list',
    HEADER_NAME: 'Product Management',
    TITLE: 'Product Management',
    TITLE_ALT: 'List Product',
  },
  PRODUCT_MANAGEMENT_ADD: {
    PATH: '/product-management/create',
    HEADER_NAME: 'Product Management',
    TITLE: 'List Product',
    TITLE_ALT: 'Product Management',
  },
  PRODUCT_MANAGEMENT_DETAIL: {
    PATH: '/product-management/detail/:id',
    HEADER_NAME: 'Product Management',
    TITLE: 'Product Detail',
    TITLE_ALT: 'Product Management',
  },
  PRODUCT_OFFER_EDIT_POSITION: {
    PATH: '/product-management/edit-position',
    HEADER_NAME: 'Product Management',
    TITLE: 'List Product',
    TITLE_ALT: 'Product Management',
  },
  PRODUCT_OFFER_SETUP_PRODUCT: {
    PATH: '/product-management/setup-product',
    HEADER_NAME: 'Product Management',
    TITLE: 'List Product',
    TITLE_ALT: 'Product Management',
  },
  PRODUCT_TODAYS_OFFER: {
    PATH: '/product-management/offer',
    HEADER_NAME: 'Product Management',
    TITLE: 'Today\'s Offer',
    TITLE_ALT: 'Today\'s Offer',
  },
}

export default ProductManagementScreens
